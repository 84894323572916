import React from "react";
import { useAppSelector } from "../../redux/hooks";
import Tooltips from "../left/Tooltips/Tooltips";
import "./right.css";

const LineTestONT: React.FC = () => {
  const { lineTestONT } = useAppSelector((state) => state.service);

  return (
    <div className="lineTestONTWrapper">
      <h3>Line test / ONT - RESULTS</h3>

      {lineTestONT.id && (
        <div className="lineTestONT">
          <p key="id">
            <i className="serviceName">ID</i>
            <i className="colon">:</i>
            {lineTestONT.id}
          </p>
          <p key="addressNumber">
            <i className="serviceName">LFC Address</i>
            <i className="colon">:</i>
            {lineTestONT.address[0]}
          </p>
          <p key="addressName">
            <i className="serviceName" />
            <i className="colon" />
            {lineTestONT.address[1]}
          </p>
          <p key="addressSuburb">
            <i className="serviceName" />
            <i className="colon" />
            {lineTestONT.address[2]}
          </p>
          <p key="addressArea">
            <i className="serviceName" />
            <i className="colon" />
            {lineTestONT.address[3]}
          </p>
          <p key="addressPostcode">
            <i className="serviceName" />
            <i className="colon" />
            {lineTestONT.address[4]}
          </p>
          <p key="serviceType">
            <i className="serviceName">Service Type</i>
            <i className="colon">:</i>
            {lineTestONT.serviceType}
          </p>
          <p key="plan">
            <i className="serviceName">Plan</i>
            <i className="colon">:</i>
            {lineTestONT.plan}
          </p>
          <p key="port">
            <i className="serviceName">Port</i>
            <i className="colon">:</i>
            {lineTestONT.port}
            {lineTestONT.port !== "1" && <i className="light amberLight" />}
            {lineTestONT.port !== "1" && (
              <Tooltips type="lineTestPort" width="normal" isAdmin={false} />
            )}
          </p>
          <p key="type">
            <i className="serviceName">Type</i>
            <i className="colon">:</i>
            {lineTestONT.type}
          </p>
          <p key="taggingMode">
            <i className="serviceName">Tagging Mode</i>
            <i className="colon">:</i>
            {lineTestONT.taggingMode}
          </p>
          <p key="circuitInsertion">
            <i className="serviceName">Circuit Insertion</i>
            <i className="colon">:</i>
            {lineTestONT.circuitInsertion}
          </p>
        </div>
      )}
    </div>
  );
};

export default LineTestONT;
