import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import type { RootState } from "./store";

export type CustomerInfoType = {
  Name: string;
  AccountID: string;
  CRM: string;
  Status: string;
  PIN: string;
  DOB: string;
  AcctName: string;
  Email: string;
  Mobile: string;
  CRMServiceAddress: string;
};

export type AccountInfoType = {
  medicalDependency: {
    status: string;
    label: string;
  };
  pendingOrders: string;
  inMigration: string;
  managed: string;
  balance: string;
  restrictionStatus: string;
  DOM: string;
  inTerm: {
    status: string;
    label: string;
  };
};

type StateType = {
  customerInfo: CustomerInfoType;
  accountInfo: AccountInfoType;
  updateTime: string;
};

const initialState: StateType = {
  customerInfo: {
    Name: "",
    AccountID: "",
    CRM: "",
    Status: "",
    DOB: "",
    PIN: "",
    AcctName: "",
    Email: "",
    Mobile: "",
    CRMServiceAddress: "",
  },
  accountInfo: {
    medicalDependency: {
      status: "",
      label: "",
    },
    pendingOrders: "",
    inMigration: "",
    managed: "",
    balance: "",
    restrictionStatus: "",
    DOM: "",
    inTerm: {
      status: "",
      label: "",
    },
  },
  updateTime: "",
};

export const customerInfoSlice = createSlice({
  name: "customerInfo",
  initialState,
  reducers: {
    updateCustomerInfoState: (
      state,
      action: PayloadAction<CustomerInfoType>
    ) => {
      state.customerInfo = { ...action.payload };
    },
    updateAccountInfoState: (state, action: PayloadAction<AccountInfoType>) => {
      state.accountInfo = { ...action.payload };
    },
    getUpdateTime: (state, action: PayloadAction<string>) => {
      state.updateTime = action.payload;
    },
    clearCustomerData: () => {
      return initialState;
    },
  },
});

export const {
  updateCustomerInfoState,
  updateAccountInfoState,
  getUpdateTime,
  clearCustomerData,
} = customerInfoSlice.actions;

export default customerInfoSlice.reducer;
