import React, { useEffect } from "react";
import { AxiosResponse } from "axios";
import SearchInputSpinner from "./SearchInputSpinner";
import AddressList from "./AddressList";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import { disableAccountSearch } from "../../../redux/globalSlice";
import "./addressChecker.css";

type Address = {
  displayAddress: string;
  slamId: string;
};

type Props = {
  addresses: AxiosResponse | Address[];
  onChange: (target: React.ChangeEvent<HTMLInputElement>) => void;
  selectedAddress: string;
  shouldShowSearchSpinner: boolean;
  onSelectAddress: (address: Address) => void;
  errorView: boolean;
  isSelected: boolean;
};

const AddressSearch: React.FC<Props> = ({
  addresses,
  onChange,
  selectedAddress,
  shouldShowSearchSpinner,
  onSelectAddress,
  errorView,
  isSelected,
}: Props) => {
  const { disableAddress } = useAppSelector((state) => state.global);
  const { userRole } = useAppSelector((state) => state.login);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (selectedAddress !== "") {
      dispatch(disableAccountSearch(true));
    } else {
      dispatch(disableAccountSearch(false));
    }
  }, [selectedAddress, dispatch]);

  return (
    <div>
      <div className="inputWrapper">
        <input
          className={`searchInput ${disableAddress ? "disableAddress" : ""}`}
          name="searchInput"
          type="text"
          value={selectedAddress}
          onChange={onChange}
          placeholder="Enter your address"
          disabled={disableAddress || userRole === "NOT FOUND"}
        />
        <p className="searchIcon" />
        {shouldShowSearchSpinner && <SearchInputSpinner />}
        {!shouldShowSearchSpinner && (
          <button
            type="button"
            className="clearInput"
            aria-label="clear"
            onClick={() => onSelectAddress({ displayAddress: "", slamId: "" })}
          />
        )}
      </div>
      {!isSelected && addresses && !!(addresses as Address[]).length && (
        <AddressList
          addresses={addresses as Address[]}
          onSelectAddress={onSelectAddress}
        />
      )}
      {errorView && (
        <p className="errorMessage">
          Cannot find customer or address, Please search with Account Number.
        </p>
      )}
    </div>
  );
};

export default AddressSearch;
