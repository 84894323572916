import React from "react";

const SignOutButton: React.FC = () => {
  const port = window.location.port ? `:${window.location.port}` : "";
  const logoutURL = `${window.location.protocol}//${window.location.hostname}${port}`;
  const handleLogout = (url: string): void => {
    window.location.href = url;
  };

  return (
    <button
      type="button"
      className="signOutButton"
      id="signOutBtn"
      onClick={() => handleLogout(logoutURL)}
    >
      Sign Out
    </button>
  );
};

export default SignOutButton;
