import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotate } from "@fortawesome/free-solid-svg-icons";
import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import { whitelistSearch } from "../../../helpers/SearchHelper";
import "./accountSearch.css";
import { clearCustomerData } from "../../../redux/customerInfoSlice";
import { clearServiceData } from "../../../redux/serviceSlice";
import {
  showProgressBar,
  disableAccountSearch,
  disableAddressSearch,
  updateCopyNotice,
  updateSearchNotice,
  clearGlobalData,
} from "../../../redux/globalSlice";
import { clearToolsetData } from "../../../redux/toolsetSlice";
import { clearSearchData } from "../../../redux/searchSlice";
import { clearWifiData } from "../../../redux/wifiSlice";

type Props = {
  clearAddressInput: () => void;
};

const AccountSearch: React.FC<Props> = ({ clearAddressInput }: Props) => {
  const {
    disableAccount,
    isSupport,
    searchNotice,
    callingInRegards,
  } = useAppSelector((state) => state.global);
  const { userRole, login, token } = useAppSelector((state) => state.login);
  const [accountNumber, setAccountNumber] = useState("");
  const [tipsToggle, setTipsToggle] = useState(false);
  const [searchLimitTime, setSearchLimitTime] = useState("15");
  const dispatch = useAppDispatch();
  const [isSearchInProgress, setIsSearchInProgress] = useState(false);
  const [isGetSearchTime, setIsGetSearchTime] = useState(false);

  const clearAllFields = () => {
    // TODO: Add more clear when new fields mapped
    setAccountNumber("");
    clearAddressInput();
    dispatch(clearCustomerData());
    dispatch(clearServiceData());
    dispatch(clearGlobalData());
    dispatch(clearToolsetData());
    dispatch(clearSearchData());
    dispatch(clearWifiData());
    dispatch(disableAccountSearch(false));
    dispatch(disableAddressSearch(false));
    dispatch(updateCopyNotice(""));
    dispatch(updateSearchNotice(""));
    dispatch(
      showProgressBar({
        isShown: false,
        pbMessage: "",
      })
    );
  };

  const notSupport = () => {
    return (
      <div className="support">
        <b>
          There are no Chorus fixed line Fibre broadband services showing for
          this account
        </b>
        <p>Currently, OneAssist does not support…</p>
        <p>Home Wireless Broadband, ADSL, VDSL, HFC or Clarify customers</p>
        <p>This version supports Siebel and TPII Chorus Fibre customers only</p>
      </div>
    );
  };

  const getSearchLimitTime = useCallback((): void => {
    if (!isGetSearchTime) {
      axios
        .get(
          `${login.adminApi}/platform/messages-public/OneAssist/key/searchLimitTime`,
          {
            headers: { Authorization: token.idToken },
          }
        )
        .then((response) => {
          setSearchLimitTime(response.data.message_text);
          setIsGetSearchTime(true);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [isGetSearchTime, login.adminApi, token.idToken]);

  const checkTimeAndSearch = () => {
    if (isSearchInProgress) {
      dispatch(
        updateSearchNotice(
          "Test still running, please wait, if you need to re-run any test, please click the individual test button below"
        )
      );
      return;
    }
    // Start the search
    whitelistSearch(accountNumber.trim(), true);
    setIsSearchInProgress(true);

    setTimeout(() => {
      setIsSearchInProgress(false);
      dispatch(updateSearchNotice(""));
    }, parseInt(searchLimitTime, 10) * 1000);
  };

  useEffect(() => {
    if (callingInRegards) {
      setAccountNumber(callingInRegards);
    }

    if (accountNumber.trim() === "") {
      dispatch(disableAddressSearch(false));
    } else {
      // Disable Address Search
      dispatch(disableAddressSearch(true));
    }
  }, [callingInRegards, accountNumber, dispatch]);

  useEffect(() => {
    getSearchLimitTime();
  }, [getSearchLimitTime]);

  return (
    <div className="accountSearchWrap">
      <button
        type="button"
        className="clearBtnWrap"
        onClick={() => {
          clearAllFields();
        }}
      >
        <FontAwesomeIcon icon={faRotate} pull="left" className="clearBtn" />
      </button>
      <input
        className={`accountSearch ${disableAccount ? "disableAccount" : ""}`}
        name="accountSearch"
        type="text"
        value={accountNumber}
        placeholder="MSISDN / Account #"
        onChange={(e) => {
          setAccountNumber(e.currentTarget.value);
        }}
        disabled={disableAccount || userRole === "NOT FOUND"}
        onKeyDown={(event) => {
          if (
            userRole !== "NOT FOUND" &&
            event.key === "Enter" &&
            accountNumber
          ) {
            checkTimeAndSearch();
          }
        }}
      />
      <p
        className="tipsIcon"
        onMouseOver={() => {
          setTipsToggle(true);
        }}
        onMouseOut={() => {
          setTipsToggle(false);
        }}
        onFocus={() => {
          setTipsToggle(true);
        }}
        onBlur={() => {
          setTipsToggle(false);
        }}
      >
        <FontAwesomeIcon icon={faCircleQuestion} pull="left" />
      </p>
      <div className={`tips ${tipsToggle ? "shown" : "hidden"}`}>
        <p>Only Siebel and TPII are supported</p>
        <p>Please double check Acc / MSISDN entered correctly</p>
        <p>Did you try both search types ? (Acc | MISIDN) ?</p>
        <p>
          Please ensure Billing Acc entered, OneAssist cannot find a customer by
          SBL Customer Acct #
        </p>
        <p>Can you find account in OneView / Native CRM ?</p>
      </div>
      <button
        type="button"
        className="searchBtn"
        onClick={() => {
          if (accountNumber) {
            checkTimeAndSearch();
          }
        }}
      >
        SEARCH
      </button>
      <p className="searchNotice">{searchNotice}</p>
      {!isSupport && notSupport()}
    </div>
  );
};

export default AccountSearch;
