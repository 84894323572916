import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type SearchTableType = {
  CRM: string;
  status: string;
  firstName: string;
  lastName: string;
  DOB: string;
  address: string;
  accountType: string;
  connectType: string;
  fixedType: string;
  vulnerable: string;
  searchKey: string;
  accountData: {
    [key: string]: string;
  };
};

export type StateType = {
  isMSISDNSearch: boolean;
  isMultiResult: boolean;
  tableGroup: SearchTableType[];
  searchAccount: string;
};

const initialState: StateType = {
  isMSISDNSearch: false,
  isMultiResult: false,
  tableGroup: [],
  searchAccount: "",
};

export const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    updateSearchType: (state, action: PayloadAction<boolean>) => {
      state.isMSISDNSearch = action.payload;
    },
    updateResultStatus: (state, action: PayloadAction<boolean>) => {
      state.isMultiResult = action.payload;
    },
    updateTableGroup: (state, action: PayloadAction<SearchTableType[]>) => {
      state.tableGroup = action.payload;
    },
    updateSearchAccount: (state, action: PayloadAction<string>) => {
      state.searchAccount = action.payload;
    },
    clearSearchData: () => {
      return initialState;
    },
  },
});

export const {
  updateSearchType,
  updateResultStatus,
  updateTableGroup,
  updateSearchAccount,
  clearSearchData,
} = searchSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value;

export default searchSlice.reducer;
