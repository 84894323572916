import {
  Category,
  CategoryServiceFactory,
  CategoryConfiguration,
  LogLevel,
} from "typescript-logging";

CategoryServiceFactory.setDefaultConfiguration(
  new CategoryConfiguration(LogLevel.Info)
);

export const loggerService = new Category("service");
export const logger = new Category("product", loggerService);
