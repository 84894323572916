import React from "react";
import { useAppSelector } from "../../redux/hooks";
import "./right.css";

const ModemAuth: React.FC = () => {
  const modemAuth = useAppSelector((state) => state.service.modemAuth);

  const checkRestart = (): boolean => {
    let connectTime = modemAuth.connectionTime;
    const dateMatchGroup = connectTime.match(
      /(\d{2})-(JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)-(\d{4})/
    );
    if (dateMatchGroup) {
      [connectTime] = dateMatchGroup;
    }
    const connectTimeSeconds = new Date(connectTime).getTime();
    const today = new Date().getTime();
    if ((today - connectTimeSeconds) / (1000 * 3600 * 24) > 30) {
      return true;
    }
    return false;
  };

  return (
    <div className="modemAuthWrapper">
      <h3>Modem authentication - RESULTS</h3>
      {modemAuth.username && (
        <p className="modemAuth">
          <i
            className={`modemLight ${
              modemAuth.isAuth ? "modemGreen" : "modemRed"
            }`}
          />
          <i className="username">{modemAuth.username} </i>
          {modemAuth.isAuth ? (
            <i className="online">
              <i className="connectTime">
                online since <b>{modemAuth.connectionTime} </b>
              </i>
              <i className="IPAddress">(ip {modemAuth.IPAddress} </i>
              <i className="serverIPAddress">
                max {modemAuth.serverIPAddresss})
              </i>
              {checkRestart() && (
                <i className="adviseRestart"> Advise modem restart</i>
              )}
            </i>
          ) : (
            <i className="offline">No response | Not found | Offline</i>
          )}
        </p>
      )}
    </div>
  );
};

export default ModemAuth;
