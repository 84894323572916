import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { updateONTOverTimeStatus } from "../../redux/toolsetSlice";
import "./ont.css";

const ONTOvertimeChart: React.FC = () => {
  const { ONTOverTime, ONTTrafficUsage } = useAppSelector(
    (state) => state.service
  );
  const { isGetONTOverTime } = useAppSelector(
    (state) => state.toolset.ONTOverTime
  );

  const chartContainerRef = useRef<HTMLDivElement>(null);
  const [hoverDate, setHoverDate] = useState("");
  const [isDateVisible, setIsDateVisible] = useState(false);
  const [datePosition, setDatePosition] = useState({ top: 0, left: 0 });
  const [isExpand, setIsExpand] = useState(false);
  const dispatch = useAppDispatch();

  const formatUsageSize = (sizeMB: string): string => {
    const bytes = parseFloat(sizeMB);

    if (bytes >= 1024) {
      const sizeInGB = (bytes / 1024).toFixed(2);
      return `${sizeInGB}GB`;
    }

    const sizeInMB = bytes.toFixed(2);
    return `${sizeInMB}MB`;
  };

  // Calculate the start and end dates for the X-axis
  const today = new Date();
  const startDate = new Date();
  startDate.setDate(today.getDate() - 21);
  startDate.setHours(23, 0, 0, 0);

  // Generate the X-axis labels
  const xAxisLabels = [];
  for (let i = 21; i >= 0; i -= 1) {
    const date = new Date(today);
    date.setDate(today.getDate() - i);
    const day = date.getDate();
    xAxisLabels.push(`${day}`);
  }

  const formatDateForXAxis = (date: Date): string => {
    const dayOptions = { day: "numeric" } as Intl.DateTimeFormatOptions;
    const monthOptions = { month: "long" } as Intl.DateTimeFormatOptions;

    const day = date.getDate();
    let suffix = "th";

    if (day === 1 || day === 21 || day === 31) {
      suffix = "st";
    } else if (day === 2 || day === 22) {
      suffix = "nd";
    } else if (day === 3 || day === 23) {
      suffix = "rd";
    }

    const dayFormatter = new Intl.DateTimeFormat(undefined, dayOptions);
    const monthFormatter = new Intl.DateTimeFormat(undefined, monthOptions);

    const formattedDay = dayFormatter.format(date);
    const formattedMonth = monthFormatter.format(date);

    return `${formattedDay}${suffix} ${formattedMonth}`;
  };

  const renderDateRange = () => {
    const startD = formatDateForXAxis(startDate);
    const endD = formatDateForXAxis(today);

    return <div>{`${startD} to ${endD}`}</div>;
  };

  // Generate the Y-axis labels
  const yAxisLabels = [];
  for (let i = 23; i >= 0; i -= 1) {
    const hour = i.toString().padStart(2, "0");
    yAxisLabels.push(`${hour}:00`);
  }

  // Create a mapping of block colors based on status
  const statusColors: { [key: string]: string } = {
    good: "white",
    switched_off: "black",
    reduced: "#ffd600",
    degraded: "#ff8200",
    interrupted: "#d31f3f",
    unknown: "grey",
  };

  // Get the status for a given date and hour
  const getStatusForTime = (date: Date, hour: number) => {
    const inputTime = new Date(date);
    inputTime.setHours(hour);

    const matchingData = ONTOverTime.find((item) => {
      const startTime = new Date(item.startTime);
      const endTime = new Date(item.endTime);
      return inputTime >= startTime && inputTime < endTime;
    });
    return matchingData ? matchingData.status : "unknown";
  };

  // Function to format the date in the desired format
  const formatDate = (date: Date, hour: number) => {
    const formattedDate = `${date.getDate()}/${
      date.getMonth() + 1
    }/${date.getFullYear()}`;
    const formattedHour = `${hour}:00`;
    return `${formattedDate} ${formattedHour}`;
  };

  // Function to show the date on mouse hover
  const showDate = (
    event: React.MouseEvent<HTMLDivElement>,
    date: Date,
    hour: number
  ) => {
    const formattedDate = formatDate(date, hour);

    const chartContainerRect = chartContainerRef.current?.getBoundingClientRect();
    if (chartContainerRect) {
      const offsetX = event.clientX - chartContainerRect.left;
      const offsetY = event.clientY - chartContainerRect.top;

      const position = {
        top: offsetY + 10,
        left: offsetX + 10,
      };

      setDatePosition(position);
      setIsDateVisible(true);
      setHoverDate(formattedDate);
    }
  };

  const hideDate = () => {
    setIsDateVisible(false);
  };

  // Generate the chart blocks
  const chartBlocks: JSX.Element[] = [];
  let hasErrors = false; // Initialize hasErrors variable as false
  for (let y = 23; y >= 0; y -= 1) {
    for (let x = 0; x < 22; x += 1) {
      const currentDay = new Date(startDate);
      currentDay.setDate(startDate.getDate() + x);
      const currentHour = y;
      const currentStatus = getStatusForTime(currentDay, currentHour);
      const blockColor = statusColors[currentStatus];

      if (
        blockColor === "black" ||
        blockColor === "#ff8200" ||
        blockColor === "#d31f3f"
      ) {
        hasErrors = true;
      }

      chartBlocks.push(
        <div
          key={`${x}-${y}`}
          className="block"
          style={{ backgroundColor: blockColor }}
          onMouseEnter={(e) => showDate(e, currentDay, currentHour)}
          onMouseLeave={() => hideDate()}
        />
      );
    }
  }

  useEffect(() => {
    // Update the ONTOverTime status based on the hasErrors variable
    if (isGetONTOverTime) {
      if (hasErrors) {
        dispatch(
          updateONTOverTimeStatus({
            isGetONTOverTime: true,
            notice: "Errors found",
          })
        );
        setIsExpand(true);
      } else {
        dispatch(
          updateONTOverTimeStatus({
            isGetONTOverTime: true,
            notice: "No errors detected",
          })
        );
        setIsExpand(false);
      }
    }
  }, [isGetONTOverTime, hasErrors, dispatch]);

  return (
    <div className="ontStatusOvertime">
      <h3>
        ONT Status Over Time
        {isGetONTOverTime &&
          (isExpand ? (
            <FontAwesomeIcon
              icon={faChevronDown}
              className="ONTChevron"
              onClick={() => setIsExpand(false)}
            />
          ) : (
            <FontAwesomeIcon
              icon={faChevronRight}
              className="ONTChevron"
              onClick={() => setIsExpand(true)}
            />
          ))}
      </h3>
      {isGetONTOverTime && (
        <div
          className={`chart-container ${isExpand ? "expand" : "collapse"}`}
          ref={chartContainerRef}
        >
          <div className="y-axis">
            {yAxisLabels.map((label) => (
              <div key={`y-axis-${label}`} className="y-axis-label">
                {label}
              </div>
            ))}
          </div>
          <div className="chart">
            <div className="blocks">{chartBlocks}</div>
          </div>
          {isDateVisible && (
            <div
              className="date-container"
              style={{
                top: datePosition.top,
                left: datePosition.left,
              }}
            >
              {hoverDate}
            </div>
          )}
          <div className="x-axis">
            {xAxisLabels.map((label) => (
              <div key={`x-axis-${label}`} className="x-axis-label">
                {label}
              </div>
            ))}
          </div>
          <div className="x-axis-date">{renderDateRange()}</div>
          <div className="trafficUsage">
            <p className="title">Traffic Usage Since Midnight</p>
            <p className="usage">
              <i className="upload">
                {ONTTrafficUsage.upload
                  ? formatUsageSize(ONTTrafficUsage.upload)
                  : "loading..."}
              </i>
              <i className="download">
                {ONTTrafficUsage.download
                  ? formatUsageSize(ONTTrafficUsage.download)
                  : "loading..."}
              </i>
            </p>
            <p className="loading">
              <i>Uploading</i>
              <i>Downloading</i>
            </p>
          </div>
          <div className="colorsInfo">
            <p>
              <i className="colorBlock yellow" />
              In service (Lower robustness | No errors)
            </p>
            <p>
              <i className="colorBlock orange" />
              Degraded service (Transmission errors detected)
            </p>
            <p>
              <i className="colorBlock red" />
              No / Interrupted service
            </p>
            <p>
              <i className="colorBlock black" />
              ONT switched off
            </p>
            <p>
              <i className="colorBlock grey" />
              No data
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ONTOvertimeChart;
