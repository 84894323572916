import React, { useState } from "react";
import { useAppSelector } from "../../../redux/hooks";
import {
  displayCustomerInfo,
  displayServiceInfo,
  displayUpdateTime,
} from "../../../helpers/SearchHelper";
import "./accountSearch.css";

const SearchTable: React.FC = () => {
  const { tableGroup, isMultiResult } = useAppSelector((state) => state.search);
  const [activeAccount, setActiveAccount] = useState(tableGroup[0].searchKey);

  // eslint-disable-next-line
  const switchRow = (accountData: any): void => {
    setActiveAccount(accountData.ACCT_NUM);
    displayCustomerInfo(accountData, true);
    displayServiceInfo(accountData);
    displayUpdateTime();
  };

  return (
    <div className="searchTable">
      <p className="searchTableTitle">Please select account:</p>
      <table>
        <tbody>
          {isMultiResult &&
            tableGroup.map((item) => (
              <tr
                key={item.searchKey}
                className={activeAccount === item.searchKey ? "active" : ""}
                onClick={() => switchRow(item.accountData)}
              >
                <td className="rowName">{item.searchKey}</td>
                <td className="rowName">{item.CRM}</td>
                <td className="rowName">{item.status}</td>
                <td className="rowName">{item.firstName}</td>
                <td className="rowName">{item.lastName}</td>
                <td className="rowName">{item.DOB}</td>
                <td className="rowName">{item.accountType}</td>
                <td className="rowName">{item.connectType}</td>
                <td className="rowName">{item.fixedType}</td>
                <td className="rowName vul_cus">{item.vulnerable}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default SearchTable;
