import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type PlanSpeedType = {
  upload: string;
  download: string;
};

type HardwareType = {
  hardware: string;
  serialNumber: string;
};

export type ServiceType = {
  lastContact: string;
  FIS: string;
  VoIP: string;
  multiVoIP: string;
  accType: string;
  samId: string;
  SLAM: string;
  LFC: string;
  serviceName: string;
  connectType: string;
  planName: string;
  planSpeed: PlanSpeedType;
  hardware: HardwareType;
  IPv4: string;
  decoAccountFlag: string | null;
};

type ModemAuthType = {
  username: string;
  isAuth: boolean;
  connectionTime: string;
  IPAddress: string;
  serverIPAddresss: string;
};

export type LANType = {
  isLineTested?: boolean;
  CV: string;
  SV: string;
  handover: string;
};

type LoginHistoryType = {
  connection_time: string;
  drop_reason: string;
  drop_cause: string;
  ip_address: string;
  disconnect_time: string;
  username: string;
};

type LineTestONTType = {
  id: string;
  address: string[];
  serviceType: string;
  plan: string;
  port: string;
  type: string;
  taggingMode: string;
  circuitInsertion: string;
};

export type InternetUsageType = {
  TRAFFIC: string;
  YEAR: string;
  MONTH: string;
  TRAFFIC_TYPE: string;
  LOGIN: string;
  TIME_GROUP: string;
  TRAFFIC_DIRECTION: string;
  TRAFFIC_UNIT: string;
  DAY: string;
};

export type ONTOverTimeType = {
  endTime: string;
  startTime: string;
  status: string;
};

type ONTTrafficUsageType = {
  upload: string;
  download: string;
};

interface EventDetailsType {
  endTime?: string;
  eventId: string;
  eventSource: string;
  eventType: string;
  outageDuration?: number;
  startTime: string;
  status: string;
  updateTime: string;
}

interface OutageType {
  startTime: string;
  eventId: string;
  networkElement: string;
  productType: string;
  serviceId: string;
  serviceType: string;
  eventDetails: EventDetailsType;
}

type StateType = {
  service: ServiceType;
  username: string;
  asid: string;
  ONT: string;
  chorusAddress: string;
  cartridge: string;
  staticIP: string;
  modemAuth: ModemAuthType;
  lan: LANType;
  lineTest: LANType;
  loginHistory: LoginHistoryType[];
  lineTestONT: LineTestONTType;
  internetUsage: InternetUsageType[];
  ONTOverTime: ONTOverTimeType[];
  ONTTrafficUsage: ONTTrafficUsageType;
  openOutage: OutageType[];
  closedOutage: OutageType[];
};

const initialState: StateType = {
  service: {
    lastContact: "",
    FIS: "",
    VoIP: "",
    multiVoIP: "",
    accType: "",
    samId: "",
    SLAM: "",
    LFC: "",
    serviceName: "",
    connectType: "",
    planName: "",
    planSpeed: {
      upload: "",
      download: "",
    },
    hardware: {
      hardware: "",
      serialNumber: "",
    },
    IPv4: "",
    decoAccountFlag: "",
  },
  username: "",
  asid: "",
  ONT: "",
  chorusAddress: "",
  cartridge: "",
  staticIP: "",
  modemAuth: {
    username: "",
    isAuth: false,
    connectionTime: "",
    IPAddress: "",
    serverIPAddresss: "",
  },
  lan: {
    CV: "",
    SV: "",
    handover: "",
  },
  lineTest: {
    CV: "",
    SV: "",
    handover: "",
  },
  loginHistory: [],
  lineTestONT: {
    id: "",
    address: [""],
    serviceType: "",
    plan: "",
    port: "",
    type: "",
    taggingMode: "",
    circuitInsertion: "",
  },
  internetUsage: [],
  ONTOverTime: [],
  ONTTrafficUsage: {
    upload: "",
    download: "",
  },
  openOutage: [],
  closedOutage: [],
};

export const serviceSlice = createSlice({
  name: "service",
  initialState,
  reducers: {
    // eslint-disable-next-line
    updateServiceState: (state, action: PayloadAction<any>) => {
      state.service = { ...action.payload };
    },
    updateASID: (state, action: PayloadAction<string>) => {
      state.asid = action.payload;
    },
    updateONT: (state, action: PayloadAction<string>) => {
      state.ONT = action.payload;
    },
    updateChorusAddress: (state, action: PayloadAction<string>) => {
      state.chorusAddress = action.payload;
    },
    updateCartridge: (state, action: PayloadAction<string>) => {
      state.cartridge = action.payload;
    },
    updateUsername: (state, action: PayloadAction<string>) => {
      state.username = action.payload;
    },
    updateStaticIP: (state, action: PayloadAction<string>) => {
      state.staticIP = action.payload;
    },
    updateLan: (state, action: PayloadAction<LANType>) => {
      state.lan = action.payload;
    },
    getLineTestLan: (state, action: PayloadAction<LANType>) => {
      state.lineTest = action.payload;
    },
    updateModemAuth: (state, action: PayloadAction<ModemAuthType>) => {
      state.modemAuth = action.payload;
    },
    updateLoginHistory: (state, action: PayloadAction<LoginHistoryType[]>) => {
      state.loginHistory = action.payload;
    },
    updateLineTestONT: (state, action: PayloadAction<LineTestONTType>) => {
      state.lineTestONT = action.payload;
    },
    updateInternetUsageData: (
      state,
      action: PayloadAction<InternetUsageType[]>
    ) => {
      state.internetUsage = action.payload;
    },
    updateONTOverTimeData: (
      state,
      action: PayloadAction<ONTOverTimeType[]>
    ) => {
      state.ONTOverTime = action.payload;
    },
    updateONTTrafficUsage: (
      state,
      action: PayloadAction<ONTTrafficUsageType>
    ) => {
      state.ONTTrafficUsage = action.payload;
    },
    updateOpenOutageData: (state, action: PayloadAction<OutageType[]>) => {
      state.openOutage = action.payload;
    },
    updateClosedOutageData: (state, action: PayloadAction<OutageType[]>) => {
      state.closedOutage = action.payload;
    },
    clearServiceData: () => {
      return initialState;
    },
  },
});

export const {
  updateServiceState,
  updateUsername,
  updateASID,
  updateONT,
  updateChorusAddress,
  updateCartridge,
  updateStaticIP,
  updateLan,
  getLineTestLan,
  updateModemAuth,
  updateLoginHistory,
  updateLineTestONT,
  updateInternetUsageData,
  updateONTOverTimeData,
  updateONTTrafficUsage,
  updateOpenOutageData,
  updateClosedOutageData,
  clearServiceData,
} = serviceSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value;

export default serviceSlice.reducer;
