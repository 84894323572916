import React from "react";

type Address = {
  displayAddress: string;
  slamId: string;
};

type Props = {
  onSelectAddress: (address: Address) => void;
  addresses: Address[];
};

const AddressList: React.FC<Props> = ({
  onSelectAddress,
  addresses,
}: Props) => {
  if (!addresses.length) {
    return null;
  }

  return (
    <div className="addressListWrap">
      <ul className="addressList">
        {addresses.map((address) => (
          <li key={address.slamId} className="listItem">
            <button
              type="button"
              onClick={() => onSelectAddress(address)}
              className="listItemButton"
            >
              {address.displayAddress}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AddressList;
