import React from "react";
import { useAppSelector } from "../../redux/hooks";
import { removeZeros } from "../../helpers/ToolsetHelper";
import "./right.css";

const LanInfo: React.FC = () => {
  const lanValue = useAppSelector((state) => state.service);
  return (
    <div className="lan">
      <h3>Check SV LAN, CV LAN, Handover link - RESULTS</h3>
      <p className="columnTitle">
        <i className="lanName" />
        <i className="colon" />
        <i className="lanValue">TPII</i>
        <i className="lanValue">LINE TEST</i>
        <i className="lanValue" />
      </p>
      <p>
        <i className="lanName">Data Handover Link</i>
        <i className="colon">:</i>
        <i className="lanValue">{lanValue.lan.handover}</i>
        <i className="lanValue">{lanValue.lineTest.handover}</i>
        <i
          className={`lanValue ${
            lanValue.lineTest.handover ? "active" : "hidden"
          }`}
        >
          <i
            className={
              lanValue.lan.handover === lanValue.lineTest.handover
                ? "matchCube"
                : "notMatchCube"
            }
          />
          {lanValue.lan.handover === lanValue.lineTest.handover
            ? "Match"
            : "Not a Match"}
        </i>
      </p>
      <p>
        <i className="lanName">SV LAN</i>
        <i className="colon">:</i>
        <i className="lanValue">{lanValue.lan.SV}</i>
        <i className="lanValue">{lanValue.lineTest.SV}</i>
        <i className={`lanValue ${lanValue.lineTest.SV ? "active" : "hidden"}`}>
          <i
            className={
              removeZeros(lanValue.lan.SV) === lanValue.lineTest.SV
                ? "matchCube"
                : "notMatchCube"
            }
          />
          {removeZeros(lanValue.lan.SV) === lanValue.lineTest.SV
            ? "Match"
            : "Not a Match"}
        </i>
      </p>
      <p>
        <i className="lanName">CV LAN</i>
        <i className="colon">:</i>
        <i className="lanValue">{lanValue.lan.CV}</i>
        <i className="lanValue">{lanValue.lineTest.CV}</i>
        <i className={`lanValue ${lanValue.lineTest.CV ? "active" : "hidden"}`}>
          <i
            className={
              removeZeros(lanValue.lan.CV) === lanValue.lineTest.CV
                ? "matchCube"
                : "notMatchCube"
            }
          />
          {removeZeros(lanValue.lan.CV) === lanValue.lineTest.CV
            ? "Match"
            : "Not a Match"}
        </i>
      </p>
    </div>
  );
};

export default LanInfo;
