import axios from "axios";
import { store } from "../redux/store";
import {
  getLineTestLan,
  updateONT,
  updateChorusAddress,
  updateLineTestONT,
  updateONTOverTimeData,
  updateONTTrafficUsage,
} from "../redux/serviceSlice";
import { showProgressBar, updateSearchNotice } from "../redux/globalSlice";
import {
  updateLanTestStatus,
  updateLanTestNotice,
  updateONTStatus,
  updatergwBandWidth,
  updateONTOverTimeStatus,
} from "../redux/toolsetSlice";

export const getStatusByTestID = async (
  TestID: string,
  type?: string,
  action?: string
  // eslint-disable-next-line
): Promise<any> => {
  const { login, token } = store.getState().login;
  try {
    const response = await axios.get(
      `${login.outageApi}/linetest/task/retrieve/${TestID}`,
      {
        headers: {
          Authorization: token.idToken,
        },
      }
    );
    if (response.status === 200) {
      if (response.data.status !== "COMPLETED") return response.data.status;
      store.dispatch(
        showProgressBar({
          isShown: false,
          pbMessage: "",
        })
      );
      if (type === "ONTStatus") {
        // ONT Status
        if (
          response.data.testResult.result.status === "good" ||
          response.data.testResult.result.status === "reduced"
        ) {
          store.dispatch(
            updateONTStatus({
              ONTStatusLight: "greenLight",
              ONTStatusNotice: "ONT Switched On",
            })
          );
        } else {
          store.dispatch(
            updateONTStatus({
              ONTStatusLight: "redLight",
              ONTStatusNotice: "ONT Switched Off",
            })
          );
        }
        if (action === "auto" || action === "lan") {
          // cv, sv, handover point
          const dataService = response.data.testResult.product.dataServices[0];
          const CV = dataService.cvlan;
          const SV = dataService.svlan;
          const handoverPoint = dataService.handoverLinkedId;
          store.dispatch(
            getLineTestLan({
              CV,
              SV,
              handover: handoverPoint,
            })
          );
          store.dispatch(updateLanTestStatus(true));
        }

        if (action === "auto" || action === "ONT") {
          // ont value
          const ONTValue = response.data.testResult.product.ont.ontSerialNumber;
          store.dispatch(updateONT(ONTValue));
          // chorus address
          const {
            addressName,
            localityName,
          } = response.data.testResult.product.address;
          const address = `${addressName || ""} ${localityName || ""}`;
          // line test ONT
          const { id } = response.data.testResult.product.address;
          const LFCAddress = [
            response.data.testResult.product.address.streetNumConcat,
            response.data.testResult.product.address.roadNameConcat,
            response.data.testResult.product.address.localityName,
            response.data.testResult.product.address.townName,
            response.data.testResult.product.address.postCodeZone,
          ];
          const serviceType = response.data.testResult.product.service.type;
          const plan = response.data.testResult.product.service.customerPlan;
          const {
            port,
            taggingMode,
            circuitInsertion,
          } = response.data.testResult.product.dataServices[0];
          const ONTType = response.data.testResult.product.dataServices[0].type;
          // address for cartridge number switch
          store.dispatch(updateChorusAddress(address));
          store.dispatch(
            updateLineTestONT({
              id,
              address: LFCAddress,
              serviceType,
              plan,
              port,
              type: ONTType,
              taggingMode,
              circuitInsertion,
            })
          );
        }
      }
      if (type === "rgwBandWidth") {
        if (
          response.data.testResult.result.dataTestResults[0].carrierDetected ===
          "true"
        ) {
          store.dispatch(
            updatergwBandWidth({
              rgwBandWidthLight: "greenLight",
              rgwBandWidthNotice: "In Service",
              rgwBandWidthDownstream:
                response.data.testResult.result.dataTestResults[0]
                  .downstreamTrafficDuringTest,
              rgwBandWidthUpstream:
                response.data.testResult.result.dataTestResults[0]
                  .upstreamTrafficDuringTest,
            })
          );
        } else {
          store.dispatch(
            updatergwBandWidth({
              rgwBandWidthLight: "redLight",
              rgwBandWidthNotice: "No Service",
              rgwBandWidthDownstream: "",
              rgwBandWidthUpstream: "",
            })
          );
        }
        const download =
          response.data.testResult.result.dataTestResults[0]
            .downstreamCurrentDayVolume;
        const upload =
          response.data.testResult.result.dataTestResults[0]
            .upstreamCurrentDayVolume;
        store.dispatch(
          updateONTTrafficUsage({
            upload,
            download,
          })
        );
      }
      return response.data.status;
    }
    throw new Error(`API call failed with status code ${response.status}`);
  } catch (error) {
    console.error(`Error in API call: ${error}`);
    throw error;
  }
};

export const getrgwBandWidthTestID = async (asid: string): Promise<string> => {
  store.dispatch(
    showProgressBar({
      isShown: true,
      pbMessage: "Getting rgwBandwidth Test ID",
    })
  );
  const formBody = {
    asid,
    testTypes: ["rgwBandwidth"],
  };
  const { login, token } = store.getState().login;
  try {
    const response = await axios.post(
      `${login.outageApi}/linetest/task/submit`,
      formBody,
      {
        headers: {
          Authorization: token.idToken,
        },
      }
    );
    return response.data.submittedTestTasks[0].testId;
  } catch (error) {
    return "error";
  }
};
// eslint-disable-next-line
export const getONTDataByTestID = async (TestID: string): Promise<any> => {
  const { login, token } = store.getState().login;
  try {
    const response = await axios.get(
      `${login.outageApi}/linetest/task/retrieve/${TestID}`,
      {
        headers: {
          Authorization: token.idToken,
        },
      }
    );
    if (response.status === 200) {
      if (response.data.status !== "COMPLETED") return response.data.status;
      store.dispatch(
        showProgressBar({
          isShown: false,
          pbMessage: "",
        })
      );
      return response.data.testResult.result.ontStatusRecords;
    }
    throw new Error(`API call failed with status code ${response.status}`);
  } catch (error) {
    console.error(`Error in API call: ${error}`);
    throw error;
  }
};

export const getONTOverTimeTestID = async (asid: string): Promise<string> => {
  store.dispatch(
    showProgressBar({
      isShown: true,
      pbMessage: "Getting ONT Over Time Test ID",
    })
  );
  const formBody = {
    asid,
    testTypes: ["statusOverTime"],
  };
  const { login, token } = store.getState().login;
  try {
    const response = await axios.post(
      `${login.outageApi}/linetest/task/submit`,
      formBody,
      {
        headers: {
          Authorization: token.idToken,
        },
      }
    );
    return response.data.submittedTestTasks[0].testId;
  } catch (error) {
    return "error";
  }
};

const loopOfGetONTOverTime = async (ONTOverTimeTestID: string, attempt = 1) => {
  let ONTOverTimeData;
  const maxAttempts = 20;
  const retryTimeout = 3000; // 3 seconds

  await new Promise((resolve) => setTimeout(resolve, retryTimeout));
  try {
    ONTOverTimeData = await getONTDataByTestID(ONTOverTimeTestID);
    if (ONTOverTimeData === "NEW" || ONTOverTimeData === "IN_PROGRESS") {
      // If status is not "COMPLETED", wait for 3 seconds and try again
      if (attempt < maxAttempts) {
        await loopOfGetONTOverTime(ONTOverTimeTestID, attempt + 1);
      }
    } else if (ONTOverTimeData === "FAILED") {
      store.dispatch(
        showProgressBar({
          isShown: false,
          pbMessage: "",
        })
      );
      store.dispatch(
        updateONTOverTimeStatus({
          isGetONTOverTime: false,
          notice: "Unknown - Cannot display results",
        })
      );
      return;
    } else {
      // success
      store.dispatch(
        showProgressBar({
          isShown: false,
          pbMessage: "",
        })
      );
      store.dispatch(
        updateONTOverTimeStatus({
          isGetONTOverTime: true,
          notice: "",
        })
      );
      store.dispatch(updateONTOverTimeData(ONTOverTimeData));
    }
  } catch (error) {
    // Handle error
    console.error(`Error: ${error}`);
    store.dispatch(
      showProgressBar({
        isShown: false,
        pbMessage: "",
      })
    );
    store.dispatch(
      updateONTOverTimeStatus({
        isGetONTOverTime: false,
        notice: "Unknown - Cannot display results",
      })
    );
  }
};

export const getONTOverTime = async (asid: string): Promise<void> => {
  store.dispatch(
    updateONTOverTimeStatus({
      isGetONTOverTime: false,
      notice: "",
    })
  );
  store.dispatch(updateONTOverTimeData([])); // clear first

  const ONTOverTimeTestID = await getONTOverTimeTestID(asid);
  if (ONTOverTimeTestID !== "error") {
    store.dispatch(
      showProgressBar({
        isShown: true,
        pbMessage: "Getting ONT Over Time",
      })
    );

    await loopOfGetONTOverTime(ONTOverTimeTestID);
  } else {
    store.dispatch(
      updateONTOverTimeStatus({
        isGetONTOverTime: false,
        notice: "Unknown - Cannot display results",
      })
    );
  }
};

const loopOfGetRgwBandWidth = async (
  rgwBandWidthTestID: string,
  attempt = 1
) => {
  let rgwBandWidthTestStatus;
  const maxAttempts = 20;
  const retryTimeout = 3000; // 3 seconds

  await new Promise((resolve) => setTimeout(resolve, retryTimeout));
  try {
    rgwBandWidthTestStatus = await getStatusByTestID(
      rgwBandWidthTestID,
      "rgwBandWidth"
    );

    if (rgwBandWidthTestStatus === "COMPLETED") {
      store.dispatch(
        showProgressBar({
          isShown: false,
          pbMessage: "",
        })
      );
      return;
    }
    if (rgwBandWidthTestStatus === "FAILED") {
      store.dispatch(
        showProgressBar({
          isShown: false,
          pbMessage: "",
        })
      );
      store.dispatch(
        updatergwBandWidth({
          rgwBandWidthLight: "amberLight",
          rgwBandWidthNotice: "Unknown - Cannot display results",
          rgwBandWidthDownstream: "",
          rgwBandWidthUpstream: "",
        })
      );
      return;
    }
    // If status is not "COMPLETED", wait for 3 seconds and try again
    if (attempt < maxAttempts) {
      await loopOfGetRgwBandWidth(rgwBandWidthTestID, attempt + 1);
    } else {
      store.dispatch(
        showProgressBar({
          isShown: false,
          pbMessage: "",
        })
      );
      store.dispatch(
        updatergwBandWidth({
          rgwBandWidthLight: "amberLight",
          rgwBandWidthNotice: "Unknown - Cannot display results",
          rgwBandWidthDownstream: "",
          rgwBandWidthUpstream: "",
        })
      );
    }
  } catch (error) {
    // Handle error
    console.error(`Error: ${error}`);
    store.dispatch(
      showProgressBar({
        isShown: false,
        pbMessage: "",
      })
    );
    store.dispatch(
      updatergwBandWidth({
        rgwBandWidthLight: "amberLight",
        rgwBandWidthNotice: "Unknown - Cannot display results",
        rgwBandWidthDownstream: "",
        rgwBandWidthUpstream: "",
      })
    );
  }
};

export const getRgwBandWidth = async (asid: string): Promise<void> => {
  try {
    const rgwBandWidthTestID = await getrgwBandWidthTestID(asid);
    if (rgwBandWidthTestID !== "error") {
      store.dispatch(
        showProgressBar({
          isShown: true,
          pbMessage: "Getting rgwBandWidth status",
        })
      );

      // New endpoint:
      await loopOfGetRgwBandWidth(rgwBandWidthTestID);
    } else {
      store.dispatch(
        updatergwBandWidth({
          rgwBandWidthLight: "redLight",
          rgwBandWidthNotice: "Invalid rgwBandWidth test ID",
          rgwBandWidthDownstream: "",
          rgwBandWidthUpstream: "",
        })
      );
    }
  } catch (error) {
    store.dispatch(
      updatergwBandWidth({
        rgwBandWidthLight: "amberLight",
        rgwBandWidthNotice: "Unknown - Cannot display results",
        rgwBandWidthDownstream: "",
        rgwBandWidthUpstream: "",
      })
    );
  }
};

export const getONTStatusTestID = async (asid: string): Promise<string> => {
  store.dispatch(
    showProgressBar({
      isShown: true,
      pbMessage: "Getting ONT Status Test ID",
    })
  );
  const formBody = {
    asid,
    testTypes: ["ontStatus"],
  };
  const { login, token } = store.getState().login;
  try {
    const response = await axios.post(
      `${login.outageApi}/linetest/task/submit`,
      formBody,
      {
        headers: {
          Authorization: token.idToken,
        },
      }
    );
    return response.data.submittedTestTasks[0].testId;
  } catch (error) {
    return "error";
  }
};

export const loopOfGetOntStatus = async (
  ONTStatusTestID: string,
  action: string,
  attempt = 1
): Promise<void> => {
  let ONTTestStatus;
  const maxAttempts = 20;
  const retryTimeout = 3000; // 3 seconds

  await new Promise((resolve) => setTimeout(resolve, retryTimeout));

  try {
    ONTTestStatus = await getStatusByTestID(
      ONTStatusTestID,
      "ONTStatus",
      action
    );
    if (ONTTestStatus === "COMPLETED") {
      store.dispatch(
        showProgressBar({
          isShown: false,
          pbMessage: "",
        })
      );
      return;
    }
    if (ONTTestStatus === "FAILED") {
      store.dispatch(
        showProgressBar({
          isShown: false,
          pbMessage: "",
        })
      );
      store.dispatch(
        updateONTStatus({
          ONTStatusLight: "amberLight",
          ONTStatusNotice: "Unknown - Cannot display results",
        })
      );
      return;
    }
    // If status is not "COMPLETED", wait for 3 seconds and try again
    if (attempt < maxAttempts) {
      await loopOfGetOntStatus(ONTStatusTestID, action, attempt + 1);
    } else {
      store.dispatch(
        showProgressBar({
          isShown: false,
          pbMessage: "",
        })
      );
      store.dispatch(
        updateONTStatus({
          ONTStatusLight: "amberLight",
          ONTStatusNotice: "Unknown - Cannot display results",
        })
      );
    }
  } catch (error) {
    // Handle error
    console.error(`Error: ${error}`);
    store.dispatch(
      showProgressBar({
        isShown: false,
        pbMessage: "",
      })
    );
    store.dispatch(
      updateONTStatus({
        ONTStatusLight: "amberLight",
        ONTStatusNotice: "Unknown - Cannot display results",
      })
    );
  }
};

export const getONTStatus = async (
  asid: string,
  action: string
): Promise<void> => {
  if (action === "auto" || action === "lan") {
    store.dispatch(
      showProgressBar({
        isShown: true,
        pbMessage: "Checking CV/SV lan...",
      })
    );
  }
  if (action === "auto" || action === "ONT") {
    store.dispatch(
      showProgressBar({
        isShown: true,
        pbMessage: "Checking ONT...",
      })
    );
  }
  try {
    const ONTStatusTestID = await getONTStatusTestID(asid);
    if (ONTStatusTestID !== "error") {
      store.dispatch(
        showProgressBar({
          isShown: true,
          pbMessage: "Getting ONT status",
        })
      );
      // New endpoint:
      await loopOfGetOntStatus(ONTStatusTestID, action);
    } else {
      store.dispatch(
        updateONTStatus({
          ONTStatusLight: "redLight",
          ONTStatusNotice: "Invalid ONT status test ID",
        })
      );
    }
  } catch (error) {
    store.dispatch(
      updateONTStatus({
        ONTStatusLight: "amberLight",
        ONTStatusNotice: "Unknown - Cannot display results",
      })
    );
  }
};

export const checkLineTest = async (
  isAutoRun: boolean,
  asid: string,
  action?: string
): Promise<void> => {
  if (isAutoRun) {
    // clear first
    store.dispatch(
      getLineTestLan({
        CV: "",
        SV: "",
        handover: "",
      })
    );
    store.dispatch(updateLanTestStatus(false));
    store.dispatch(updateLanTestNotice(""));
    store.dispatch(updateONT(""));
    store.dispatch(
      updateONTStatus({
        ONTStatusLight: "",
        ONTStatusNotice: "",
      })
    );
    store.dispatch(
      updatergwBandWidth({
        rgwBandWidthLight: "",
        rgwBandWidthNotice: "",
        rgwBandWidthDownstream: "",
        rgwBandWidthUpstream: "",
      })
    );
    store.dispatch(
      updateLineTestONT({
        id: "",
        address: [""],
        serviceType: "",
        plan: "",
        port: "",
        type: "",
        taggingMode: "",
        circuitInsertion: "",
      })
    );
  }

  if (asid) {
    if (isAutoRun) {
      await getONTStatus(asid, "auto");
      const { ONTStatusLight } = store.getState().toolset.ONTStatus;
      if (ONTStatusLight === "greenLight") {
        await getRgwBandWidth(asid);
        if (
          store.getState().toolset.rgwBandWidth.rgwBandWidthLight ===
          "greenLight"
        ) {
          getONTOverTime(asid);
        } else {
          store.dispatch(
            updateSearchNotice(
              "ONT issue identified - OneAssist will not return additional test results until issue rectified"
            )
          );
        }
      } else {
        store.dispatch(
          updateSearchNotice(
            "ONT issue identified - OneAssist will not return additional test results until issue rectified"
          )
        );
      }
    } else if (action === "lan") {
      // clear first
      store.dispatch(
        getLineTestLan({
          CV: "",
          SV: "",
          handover: "",
        })
      );
      store.dispatch(updateLanTestNotice(""));
      store.dispatch(updateLanTestStatus(false));
      getONTStatus(asid, "lan");
    } else if (action === "ONT") {
      // clear first
      store.dispatch(updateONT(""));
      store.dispatch(
        updateONTStatus({
          ONTStatusLight: "",
          ONTStatusNotice: "",
        })
      );
      store.dispatch(
        updatergwBandWidth({
          rgwBandWidthLight: "",
          rgwBandWidthNotice: "",
          rgwBandWidthDownstream: "",
          rgwBandWidthUpstream: "",
        })
      );
      store.dispatch(
        updateLineTestONT({
          id: "",
          address: [""],
          serviceType: "",
          plan: "",
          port: "",
          type: "",
          taggingMode: "",
          circuitInsertion: "",
        })
      );
      getONTStatus(asid, "ONT");
      getRgwBandWidth(asid);
    }
  } else {
    store.dispatch(updateLanTestStatus(false));
    store.dispatch(updateLanTestNotice("No ASID found"));
    store.dispatch(
      updateONTStatus({
        ONTStatusLight: "redLight",
        ONTStatusNotice: "No ASID found",
      })
    );
  }
};
