import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type ProgressBarType = {
  isShown: boolean;
  pbMessage: string;
};

type PINNoticeType = {
  color: string;
  text: string;
};

export type StateType = {
  callingInRegards: string;
  progressBar: ProgressBarType;
  disableAccount: boolean;
  disableAddress: boolean;
  copyNotice: string;
  isSupport: boolean;
  searchNotice: string;
  isShowAddressError: boolean;
  cartridgeGroup: string[];
  vulnerable: boolean;
  isVoIP: boolean;
  isSiebelAccount: boolean;
  pinNotice: PINNoticeType;
  pinAttempts: number;
  pinGlobal: string;
};

const initialState: StateType = {
  callingInRegards: "",
  progressBar: {
    isShown: false,
    pbMessage: "",
  },
  disableAccount: false,
  disableAddress: false,
  copyNotice: "",
  isSupport: true,
  searchNotice: "",
  isShowAddressError: false,
  cartridgeGroup: [""],
  vulnerable: false,
  isVoIP: false,
  isSiebelAccount: false,
  pinNotice: {
    color: "",
    text: "",
  },
  pinAttempts: 0,
  pinGlobal: "",
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    getCIRNumber: (state, action: PayloadAction<string>) => {
      state.callingInRegards = action.payload;
    },
    showProgressBar: (state, action: PayloadAction<any>) => { //eslint-disable-line
      state.progressBar = action.payload;
    },
    disableAccountSearch: (state, action: PayloadAction<boolean>) => {
      state.disableAccount = action.payload;
    },
    disableAddressSearch: (state, action: PayloadAction<boolean>) => {
      state.disableAddress = action.payload;
    },
    updateCopyNotice: (state, action: PayloadAction<string>) => {
      state.copyNotice = action.payload;
    },
    updateSupport: (state, action: PayloadAction<boolean>) => {
      state.isSupport = action.payload;
    },
    updateSearchNotice: (state, action: PayloadAction<string>) => {
      state.searchNotice = action.payload;
    },
    showAddressError: (state, action: PayloadAction<boolean>) => {
      state.isShowAddressError = action.payload;
    },
    updateCartridgeGroup: (state, action: PayloadAction<string[]>) => {
      state.cartridgeGroup = action.payload;
    },
    updateVulnerable: (state, action: PayloadAction<boolean>) => {
      state.vulnerable = action.payload;
    },
    updateVoIP: (state, action: PayloadAction<boolean>) => {
      state.isVoIP = action.payload;
    },
    updateSiebelStatus: (state, action: PayloadAction<boolean>) => {
      state.isSiebelAccount = action.payload;
    },
    updatePinNotice: (state, action: PayloadAction<PINNoticeType>) => {
      state.pinNotice = action.payload;
    },
    updatePinAttempts: (state, action: PayloadAction<number>) => {
      state.pinAttempts = action.payload;
    },
    updatePinGlobal: (state, action: PayloadAction<string>) => {
      state.pinGlobal = action.payload;
    },
    clearGlobalData: () => {
      return initialState;
    },
  },
});

export const {
  getCIRNumber,
  showProgressBar,
  disableAccountSearch,
  disableAddressSearch,
  updateCopyNotice,
  updateSupport,
  updateSearchNotice,
  showAddressError,
  updateCartridgeGroup,
  updateVulnerable,
  updateVoIP,
  updateSiebelStatus,
  updatePinNotice,
  updatePinAttempts,
  updatePinGlobal,
  clearGlobalData,
} = globalSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value;

export default globalSlice.reducer;
