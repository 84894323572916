import React, { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import FAQDropdown from "../../top/FAQDropdown";
import Tooltips from "../Tooltips/Tooltips";
import { siebelPinOptions } from "../../../constants/defaultConfig";
import { getPINVerify } from "../../../helpers/AssistHelper";
import { updatePinNotice } from "../../../redux/globalSlice";
import "./pinVerify.css";

const PinVerify: React.FC = () => {
  const [pinNumber, setPinNumber] = useState("");
  const [isDisablePinInput, setIsDisablePinInput] = useState(true);
  const [isDisableVerifyBtn, setIsDisableVerifyBtn] = useState(true);
  const [inputTextColor, setInputTextColor] = useState("");
  const {
    isSiebelAccount,
    isSupport,
    pinNotice,
    pinAttempts,
    pinGlobal,
  } = useAppSelector((state) => state.global);

  const dispatch = useAppDispatch();

  const handlePinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.currentTarget.value;
    const isValidInput = /^\d*$/.test(input); // Check if input contains only digits

    if (!isValidInput) {
      dispatch(
        updatePinNotice({
          color: "red",
          text: "Only accept digits",
        })
      );
      setIsDisableVerifyBtn(true);
    } else if (input.length < 4) {
      dispatch(
        updatePinNotice({
          color: "red",
          text: "4 digits required",
        })
      );
      setIsDisableVerifyBtn(true);
    } else {
      dispatch(
        updatePinNotice({
          color: "",
          text: "",
        })
      );
      setIsDisableVerifyBtn(false);
      setInputTextColor("");
    }

    setPinNumber(input.slice(0, 4)); // Limit input to 4 digits
  };

  useEffect(() => {
    if (isSiebelAccount && isSupport) {
      setIsDisablePinInput(false);
    } else {
      setIsDisablePinInput(true);
    }

    // clear input
    if (!pinGlobal) {
      setPinNumber("");
    }
  }, [isSiebelAccount, isSupport, pinGlobal]);

  useEffect(() => {
    setInputTextColor(pinNotice.color);
    // third time failed, disable input
    if (
      pinNotice.text ===
      "Failed to ID - USE CAUTION - Please verify your customer via THREE security questions"
    ) {
      setIsDisablePinInput(true);
      setIsDisableVerifyBtn(true);
    }
  }, [pinNotice.color, pinNotice.text]);

  return (
    <div className="pinVerifyArea">
      <div className="pinDropdown">
        <FAQDropdown options={siebelPinOptions} />
      </div>
      <input
        className={`pinInput ${
          isDisablePinInput ? "pinInputDisable" : ""
        } ${inputTextColor}`}
        name="pinInput"
        type="text"
        value={pinNumber}
        placeholder="4 Digit PIN"
        onChange={handlePinChange}
        disabled={isDisablePinInput}
      />
      <Tooltips type="PinVerify" width="normal" isAdmin />
      <button
        type="button"
        className={`pinVerifyBtn ${
          isDisableVerifyBtn || pinAttempts === 3 ? "pinVerifyBtnDisable" : ""
        }`}
        onClick={() => {
          getPINVerify(pinNumber);
        }}
        disabled={isDisableVerifyBtn}
      >
        VERIFY
      </button>
      {pinNotice.text && (
        <p className={`pinNotice notice-${pinNotice.color}`}>
          {pinNotice.text}
        </p>
      )}
    </div>
  );
};

export default PinVerify;
