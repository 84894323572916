/* eslint-disable react/jsx-props-no-spreading */
import React from "react";

const SearchInputSpinner: React.FC = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64px"
      height="64px"
      viewBox="0 0 128 128"
      className="searchSpinner"
      {...props}
    >
      <g>
        <path d="M59.6 0h8v40h-8V0z" fill="#000000" fillOpacity="1" />
        <path
          d="M59.6 0h8v40h-8V0z"
          fill="#cccccc"
          fillOpacity="0.2"
          transform="rotate(30 64 64)"
        />
        <path
          d="M59.6 0h8v40h-8V0z"
          fill="#cccccc"
          fillOpacity="0.2"
          transform="rotate(60 64 64)"
        />
        <path
          d="M59.6 0h8v40h-8V0z"
          fill="#cccccc"
          fillOpacity="0.2"
          transform="rotate(90 64 64)"
        />
        <path
          d="M59.6 0h8v40h-8V0z"
          fill="#cccccc"
          fillOpacity="0.2"
          transform="rotate(120 64 64)"
        />
        <path
          d="M59.6 0h8v40h-8V0z"
          fill="#b2b2b2"
          fillOpacity="0.3"
          transform="rotate(150 64 64)"
        />
        <path
          d="M59.6 0h8v40h-8V0z"
          fill="#999999"
          fillOpacity="0.4"
          transform="rotate(180 64 64)"
        />
        <path
          d="M59.6 0h8v40h-8V0z"
          fill="#7f7f7f"
          fillOpacity="0.5"
          transform="rotate(210 64 64)"
        />
        <path
          d="M59.6 0h8v40h-8V0z"
          fill="#666666"
          fillOpacity="0.6"
          transform="rotate(240 64 64)"
        />
        <path
          d="M59.6 0h8v40h-8V0z"
          fill="#4c4c4c"
          fillOpacity="0.7"
          transform="rotate(270 64 64)"
        />
        <path
          d="M59.6 0h8v40h-8V0z"
          fill="#333333"
          fillOpacity="0.8"
          transform="rotate(300 64 64)"
        />
        <path
          d="M59.6 0h8v40h-8V0z"
          fill="#191919"
          fillOpacity="0.9"
          transform="rotate(330 64 64)"
        />
        <animateTransform
          attributeName="transform"
          type="rotate"
          values="0 64 64;30 64 64;60 64 64;90 64 64;120 64 64;150 64 64;180 64 64;210 64 64;240 64 64;270 64 64;300 64 64;330 64 64"
          calcMode="discrete"
          dur="1080ms"
          repeatCount="indefinite"
        />
      </g>
    </svg>
  );
};

export default SearchInputSpinner;
