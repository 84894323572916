import React, { useState } from "react";

export interface DropdownOption {
  value: string;
  label: string;
  link: string;
}

export interface DropdownProps {
  options: DropdownOption[];
  title?: string;
}

const FAQDropdown: React.FC<DropdownProps> = ({
  options,
  title,
}: DropdownProps) => {
  const [selectedValue, setSelectedValue] = useState<string | undefined>(
    undefined
  );

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = options.find(
      (option) => option.value === event.target.value
    );
    if (selectedOption) {
      window.open(selectedOption.link, "_blank");
    }
    setSelectedValue(event.target.value);
  };

  return (
    <select
      value={selectedValue}
      onChange={handleChange}
      className="faqDropdown"
    >
      {title ? (
        <option value="">{title}</option>
      ) : (
        <option value="">Additional Support</option>
      )}
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default FAQDropdown;
