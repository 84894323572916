import React from "react";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import {
  updateCartridge,
  updateChorusAddress,
} from "../../../redux/serviceSlice";
import { getTPIIUserName } from "../../../helpers/SearchHelper";
import { clearToolsetData } from "../../../redux/toolsetSlice";
import { updateSupport } from "../../../redux/globalSlice";
import "./accountSearch.css";

const CartridgeList: React.FC = () => {
  const { cartridge, chorusAddress } = useAppSelector((state) => state.service);
  const { cartridgeGroup } = useAppSelector((state) => state.global);
  const dispatch = useAppDispatch();

  const switchCartridge = (newCartridge: string) => {
    dispatch(updateSupport(true));
    dispatch(updateChorusAddress(""));
    dispatch(updateCartridge(newCartridge));
    dispatch(clearToolsetData());
    getTPIIUserName(newCartridge, true);
  };

  return (
    <div className="cartridgeList">
      {cartridgeGroup.map((item, index) => (
        <div className="cartridgeItem" key={`cartridgeItem_${index + 1}`}>
          <p
            className={`cartridge ${
              item === cartridge ? "cartridgeActive" : ""
            }`}
            key={`cartridge_${index + 1}`}
            onClick={() => {
              switchCartridge(item);
            }}
            aria-hidden="true"
          >
            {item} (provisioned service)
          </p>
          {item === cartridge && (
            <p className="chorusAddress" key={`address_${index + 1}`}>
              {chorusAddress}
            </p>
          )}
        </div>
      ))}
    </div>
  );
};

export default CartridgeList;
