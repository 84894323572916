import React, { useState } from "react";
// import WIFIToolset from "./WIFIToolset";
import FixedToolset from "./FixedToolset";
// import MobileToolset from "./MobileToolset";
import "./toolset.css";

const ToolsetWrapper: React.FC = () => {
  const [expendIndex, setExpendIndex] = useState(2); // Back to 1 when other toolset in used

  return (
    <div className="toolsetWrapper">
      {/* <WIFIToolset expendIndex={expendIndex} onSelectMenu={setExpendIndex} /> */}
      <FixedToolset expendIndex={expendIndex} onSelectMenu={setExpendIndex} />
      {/* <MobileToolset expendIndex={expendIndex} onSelectMenu={setExpendIndex} /> */}
    </div>
  );
};

export default ToolsetWrapper;
