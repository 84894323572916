import React, { useState, useCallback, useEffect } from "react";
import axios, { AxiosResponse } from "axios";
import AddressSearch from "./AddressSearch";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { getServiceFeasibility } from "../../../helpers/SearchHelper";
import {
  disableAccountSearch,
  showAddressError,
} from "../../../redux/globalSlice";

type Address = {
  displayAddress: string;
  slamId: string;
};

type Props = {
  isClearAddress: boolean;
  hasAddress: () => void;
};

const AddressChecker: React.FC<Props> = ({
  isClearAddress,
  hasAddress,
}: Props) => {
  const [shouldShowSearchSpinner, setShouldShowSearchSpinner] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState("");
  const { isShowAddressError } = useAppSelector((state) => state.global);
  const [addresses, setAddresses] = useState<AxiosResponse | Address[]>([]);
  const [isSelected, setIsSelected] = useState(false);
  const { login, token } = useAppSelector((state) => state.login);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isClearAddress) {
      setAddresses([]);
      setSelectedAddress("");
      dispatch(showAddressError(false));
      setShouldShowSearchSpinner(false);
    }
  }, [isClearAddress, dispatch]);

  const resetAddresses = useCallback(() => {
    setAddresses([]);
  }, []);

  const searchAddress = useCallback(
    (query: string) => {
      setTimeout(() => {
        axios
          .get(`${login.oneAssistApi}/address-checker/search?query=${query}`, {
            headers: {
              Authorization: token.idToken,
            },
          })
          .then((response) => {
            if (!response.data) {
              dispatch(showAddressError(true));
              resetAddresses();
            } else {
              setAddresses(response.data.addresses);
              dispatch(showAddressError(false));
            }
          })
          .catch(() => {
            dispatch(showAddressError(true));
            resetAddresses();
          })
          .finally(() => {
            setShouldShowSearchSpinner(false);
          });
      }, 500);
    },
    [resetAddresses, dispatch, login.oneAssistApi, token]
  );

  const onHandleChange = useCallback(
    ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      hasAddress();
      setIsSelected(false);
      const { value: query } = target;
      if (query !== "") {
        setShouldShowSearchSpinner(true);
      } else {
        setShouldShowSearchSpinner(false);
      }
      setSelectedAddress(query);
      dispatch(showAddressError(false));
      resetAddresses();
      const atLeastTwoLetters = new RegExp("(?=(?:.*?[A-Za-z]){2})");
      if (atLeastTwoLetters.test(query) && query.length >= 3) {
        setSelectedAddress(query);
        searchAddress(query);
      }
    },
    [resetAddresses, searchAddress, hasAddress, dispatch]
  );

  const onSelectAddress = useCallback(
    (address: Address) => {
      // clear input
      if (address.displayAddress === "") {
        setSelectedAddress("");
        setIsSelected(true);
        dispatch(showAddressError(false));
        return;
      }
      const chosenAddress = (addresses as Address[]).filter(
        (a) => a.displayAddress === address.displayAddress
      )[0];
      // display chosen address in capitalized format
      const displayChosenAddress = chosenAddress.displayAddress
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
        .join(" ");
      setSelectedAddress(displayChosenAddress);
      searchAddress(displayChosenAddress);
      setIsSelected(true);
      // get service feasibility
      getServiceFeasibility(chosenAddress.slamId, false);
      dispatch(disableAccountSearch(true));
    },
    [addresses, searchAddress, dispatch]
  );

  return (
    <div className="addressCheckerWrap">
      <AddressSearch
        onChange={onHandleChange}
        selectedAddress={selectedAddress}
        shouldShowSearchSpinner={shouldShowSearchSpinner}
        addresses={addresses}
        onSelectAddress={onSelectAddress}
        errorView={isShowAddressError}
        isSelected={isSelected}
      />
    </div>
  );
};

export default AddressChecker;
