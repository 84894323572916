import React from "react";
import { useAppSelector } from "../../redux/hooks";
import SignOutButton from "../login/SignOutButton";
import Tooltips from "../left/Tooltips/Tooltips";
import FAQDropdown from "./FAQDropdown";
import { faqOptions } from "../../constants/defaultConfig";

const StateWrapper: React.FC = () => {
  const { isAuthenticated, username } = useAppSelector((state) => state.login);
  const { updateTime } = useAppSelector((state) => state.customer);
  const { vulnerable } = useAppSelector((state) => state.global);

  return (
    <div className="stateWrapper">
      {vulnerable && (
        <div className="vulnerable">
          <p>
            VUNERABLE CUSTOMER <i className="light" />
          </p>
        </div>
      )}
      {updateTime && (
        <div className="updateTime">
          <Tooltips type="UpdateTime" width="normal" isAdmin={false} />
          <p className="updateTimeText">
            Account data last updated {updateTime}
          </p>
        </div>
      )}
      {isAuthenticated && (
        <div className="auth-header">
          <p className="welcome">
            Welcome back <i className="welcomeName">{username}</i>
          </p>
          <SignOutButton />
        </div>
      )}
      <div className="faq">
        <FAQDropdown options={faqOptions} />
      </div>
    </div>
  );
};

export default StateWrapper;
