import React, { useState, useEffect, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { useAppSelector } from "../../redux/hooks";
import {
  formattedDate,
  formatOutageDuration,
} from "../../helpers/FormatHelper";
import "./right.css";

const Outages: React.FC = () => {
  const { openOutage, closedOutage } = useAppSelector((state) => state.service);
  const {
    unplannedOutage,
    plannedOutage,
    inEffectOutage,
    futureOutage,
  } = useAppSelector((state) => state.toolset);
  const [showHistory, setShowHistory] = useState(false);
  const [showViewAllLink, setShowViewAllLink] = useState(false);

  const handleToggleClick = () => {
    setShowHistory(true);
  };

  const checkViewAllLink = useCallback(() => {
    if (
      unplannedOutage.outageNotice === "No outage reported" ||
      plannedOutage.outageNotice === "No outage reported" ||
      futureOutage.outageNotice === "Unknown"
    ) {
      setShowViewAllLink(false);
    } else if (
      unplannedOutage.outageNotice === "" &&
      plannedOutage.outageNotice === "" &&
      futureOutage.outageNotice === "" &&
      inEffectOutage.outageNotice === ""
    ) {
      setShowViewAllLink(false);
    } else {
      setShowViewAllLink(true);
    }
  }, [
    inEffectOutage.outageNotice,
    futureOutage.outageNotice,
    unplannedOutage.outageNotice,
    plannedOutage.outageNotice,
  ]);

  useEffect(() => {
    checkViewAllLink();
  }, [checkViewAllLink]);

  return (
    <div className="outages">
      <h3>Outages</h3>
      <div className="outagesContent">
        {unplannedOutage.outageNotice && (
          <p className="outageNotice">
            <i className={`${unplannedOutage.outageLight}`} />
            {unplannedOutage.outageNotice}
          </p>
        )}
        {plannedOutage.outageNotice && (
          <p className="outageNotice">
            <i className={`${plannedOutage.outageLight}`} />
            {plannedOutage.outageNotice}
          </p>
        )}
        {inEffectOutage.outageNotice && (
          <p className="outageNotice">
            <i className={`${inEffectOutage.outageLight}`} />
            {inEffectOutage.outageNotice}
          </p>
        )}
        {futureOutage.outageNotice && (
          <p className="outageNotice">
            <i className={`${futureOutage.outageLight}`} />
            {futureOutage.outageNotice}
          </p>
        )}
        {closedOutage.length > 0 && (
          <p
            onClick={handleToggleClick}
            className="showHistory"
            aria-hidden="true"
          >
            Show known outage history
          </p>
        )}
        {showViewAllLink && (
          <p className="viewAll">
            <a
              href="https://one.nz/help/network-status/#landlineandbroadband"
              target="_blank"
              rel="noreferrer"
            >
              VIEW ALL
            </a>
            NZ Customer & Service delivery impacting events
          </p>
        )}
        {Object.values(openOutage).map((outage) => (
          <div key={outage.eventId} className="eventDetail">
            <h4>EventDetail</h4>
            <p>Chorus Reference: {outage.eventId}</p>
            <p>Status: {outage.eventDetails.status}</p>
            <p>Event Type: {outage.eventDetails.eventType}</p>
            <p>Start Time: {formattedDate(outage.eventDetails.startTime)}</p>
            {outage.eventDetails.eventType === "Planned" &&
              outage.eventDetails.endTime && (
                <p>End Time: {formattedDate(outage.eventDetails.endTime)}</p>
              )}
            {outage.eventDetails.eventType === "Planned" &&
              outage.eventDetails.outageDuration && (
                <p>
                  Duration:
                  {formatOutageDuration(outage.eventDetails.outageDuration)}
                </p>
              )}

            <h4>
              <FontAwesomeIcon icon={faChevronDown} className="chevronDown" />
              List of impacted services:
            </h4>
            <table>
              <thead>
                <tr>
                  <th>Service ID</th>
                  <th>Plan / ProdType</th>
                  <th>Customer</th>
                  <th>Network</th>
                  <th>Element Service Type</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{outage.serviceId}</td>
                  <td>{outage.productType}</td>
                  <td>Vodafone</td>
                  <td>{outage.networkElement}</td>
                  <td>{outage.serviceType}</td>
                </tr>
              </tbody>
            </table>
          </div>
        ))}

        {showHistory && (
          <>
            <h4>
              <FontAwesomeIcon icon={faChevronDown} className="chevronDown" />
              History
            </h4>
            {Object.values(closedOutage).map((outage) => (
              <div key={outage.eventId} className="eventDetail">
                <h4>EventDetail</h4>
                <p>Chorus Reference: {outage.eventId}</p>
                <p>Status: {outage.eventDetails.status}</p>
                <p>Event Type: {outage.eventDetails.eventType}</p>
                <p>
                  Start Time: {formattedDate(outage.eventDetails.startTime)}
                </p>
                <p>
                  End Time:{" "}
                  {outage.eventDetails.endTime
                    ? formattedDate(outage.eventDetails.endTime)
                    : "N/A"}
                </p>
                <p>
                  Outage Duration:{" "}
                  {outage.eventDetails.outageDuration
                    ? formatOutageDuration(outage.eventDetails.outageDuration)
                    : "N/A"}
                </p>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default Outages;
