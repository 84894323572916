import React, { useState } from "react";
import AccountSearch from "../AccountSearch/AccountSearch";
import AddressChecker from "../AddressChecker/AddressChecker";
// import TreeView from "../Treeview/Treeview";
import CartridgeList from "../AccountSearch/CartridgeList";
import SearchTable from "../AccountSearch/SearchTable";
import { useAppSelector } from "../../../redux/hooks";
import "./inputwrapper.css";

const InputWrapper: React.FC = () => {
  const [clearAddress, setClearAddress] = useState(false);
  const { cartridge, service } = useAppSelector((state) => state.service);
  const { isMultiResult } = useAppSelector((state) => state.search);

  return (
    <div className="searchArea">
      <div className="inputArea">
        <div className="inputLeft">
          <AccountSearch
            clearAddressInput={() => {
              setClearAddress(true);
            }}
          />
          {/* <TreeView /> */}
        </div>
        <AddressChecker
          isClearAddress={clearAddress}
          hasAddress={() => {
            setClearAddress(false);
          }}
        />
      </div>
      {isMultiResult && <SearchTable />}
      {service.FIS === "Yes" && cartridge && <CartridgeList />}
    </div>
  );
};

export default InputWrapper;
