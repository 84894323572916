import React from "react";
import { useAppSelector } from "../../redux/hooks";

const ProgressBar: React.FC = () => {
  const { isShown, pbMessage } = useAppSelector(
    (state) => state.global.progressBar
  );
  return (
    <div className={`progressBarWrapper ${isShown ? "pbActive" : "pbHidden"}`}>
      <div className="progressBar">
        <div className="movement" />
      </div>
      <p className="pbMessage">{pbMessage}</p>
    </div>
  );
};

export default ProgressBar;
