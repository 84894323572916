import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import serviceReducer from "./serviceSlice";
import customerReducer from "./customerInfoSlice";
import loginReducer from "./loginSlice";
import globalReducer from "./globalSlice";
import toolsetReducer from "./toolsetSlice";
import searchReducer from "./searchSlice";
import wifiReducer from "./wifiSlice";

export const store = configureStore({
  reducer: {
    service: serviceReducer,
    customer: customerReducer,
    login: loginReducer,
    global: globalReducer,
    toolset: toolsetReducer,
    search: searchReducer,
    wifi: wifiReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
