/* eslint-disable class-methods-use-this */
import React from "react";
import { logger } from "./config/Config";
import { IClientConfig } from "./config/ClientConfig";
import "amazon-connect-streams";
import "./style/App.css";
import { store } from "./redux/store";
import { updateEnv } from "./redux/loginSlice";
import LoginLayout from "./components/login/LoginLayout";

type ConfigFunction = () => IClientConfig;

declare global {
  interface Window {
    get_client_config: ConfigFunction;
  }
}

export default class App extends React.Component<unknown, unknown> {
  environment = "";

  componentDidMount(): void {
    const script = document.createElement("script");
    let confURL = process.env["REACT_APP_CONFIG_URL"]; // eslint-disable-line @typescript-eslint/dot-notation
    if (!confURL) {
      confURL = "Config.js";
    }
    script.src = confURL;
    script.async = true;
    script.onload = () => this.configLoaded();
    document.body.appendChild(script);
  }

  configLoaded(): void {
    logger.info("configLoaded");
    const config = window.get_client_config();
    this.environment = config.environment;
    logger.info(this.environment);
    store.dispatch(
      updateEnv({
        env: config.environment,
        poolId: config.poolId,
        poolDomain: config.poolDomain,
        clientId: config.clientId,
        region: config.region,
        oneAssistApi: config.oneAssistApiUrl,
        adminApi: config.adminApiUrl,
        useTPIIProxy: config.useTPIIProxy,
        outageApi: config.lfcOutagesApiUrl,
      })
    );
  }

  render(): JSX.Element | null {
    return (
      <div className="app">
        <LoginLayout />
      </div>
    );
  }
}
