import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWifi, faQuestion } from "@fortawesome/free-solid-svg-icons";
import { useAppSelector } from "../../redux/hooks";
import "./right.css";

const LoginHistory: React.FC = () => {
  const { loginHistory } = useAppSelector((state) => state.service);

  return (
    <div className="loginWrapper">
      <h3>Login History</h3>
      {loginHistory.length > 0 && (
        <div className="loginHistory">
          <p className="loginIcons">
            <FontAwesomeIcon
              icon={faWifi}
              pull="left"
              className="loginIcon onlineIcon"
            />
            <FontAwesomeIcon
              icon={faWifi}
              pull="left"
              className="loginIcon offlineIcon"
            />
            <i className="ipIcon" />
            <FontAwesomeIcon
              icon={faQuestion}
              pull="left"
              className="loginIcon questionIcon"
            />
          </p>
          <p className="loginTitles">
            <i className="onlineTitle">Online</i>
            <i className="offlineTitle">Offline</i>
          </p>
          <div className="loginLines">
            {loginHistory.map((item, index) => (
              <p className="loginLine" key={`loginLine_${index + 1}`}>
                <i className="lineColumn connectionTime">
                  {item.connection_time}
                </i>
                <i className="lineColumn disconnectTime">
                  {item.disconnect_time}
                </i>
                <i className="lineColumn ipAddress">{item.ip_address}</i>
                <i className="lineColumn dropReason">
                  {item.drop_cause} {item.drop_reason}
                </i>
              </p>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default LoginHistory;
