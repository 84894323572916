import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";
import { tooltipsConfig } from "../../../constants/defaultConfig";
import { useAppSelector } from "../../../redux/hooks";
import "./tooltips.css";

type Props = {
  type: string;
  width: string;
  isAdmin: boolean;
};

const Tooltips: React.FC<Props> = ({ type, width, isAdmin }: Props) => {
  const { adminApi } = useAppSelector((state) => state.login.login);
  const { idToken } = useAppSelector((state) => state.login.token);
  const [contentText, setContentText] = useState(
    tooltipsConfig[type as keyof typeof tooltipsConfig].text
  );
  const [size, setSize] = useState(
    tooltipsConfig[type as keyof typeof tooltipsConfig].size
  );
  const [linkUrl, setLinkUrl] = useState(
    tooltipsConfig[type as keyof typeof tooltipsConfig].linkUrl
  );
  const [linkText, setLinkText] = useState(
    tooltipsConfig[type as keyof typeof tooltipsConfig].linkText
  );

  const getTooltipsFromAdminPortal = useCallback(
    (tooltipsType: string): void => {
      axios
        .get(
          `${adminApi}/platform/messages-public/OneAssist/key/tooltip${tooltipsType}`,
          {
            headers: { Authorization: idToken },
          }
        )
        .then((response) => {
          setContentText(response.data.message_text);
          setSize(response.data.size || "");
          setLinkText(response.data.link_text || "");
          setLinkUrl(response.data.link_url || "");
        })
        .catch((error) => {
          console.error(error);
        });
    },
    [adminApi, idToken]
  );

  useEffect(() => {
    if (isAdmin) {
      getTooltipsFromAdminPortal(type);
    }
  }, [isAdmin, getTooltipsFromAdminPortal, type]);

  return (
    <div className="tooltip-container">
      <p className="tooltips-icon">
        <FontAwesomeIcon icon={faCircleQuestion} pull="left" />
      </p>
      <div className={`tooltip-content ${size} ${width}`}>
        <div dangerouslySetInnerHTML={{ __html: contentText }} />
        {linkUrl ? (
          <p className="align-right">
            {" "}
            <a
              href={linkUrl}
              className="tooltip-link"
              target="_blank"
              rel="noreferrer"
            >
              {linkText}
            </a>
          </p>
        ) : (
          <p />
        )}
      </div>
    </div>
  );
};

export default Tooltips;
