import React from "react";
import { useAppSelector } from "../../redux/hooks";
import "./right.css";

const ServiceInfo: React.FC = () => {
  const serviceValue = useAppSelector((state) => state.service);

  const lastContactStyle = (input: string): string => {
    let style = "";
    if (input === "Day" || input === "Week") {
      style = "redBold";
    } else if (input === "3 Weeks" || input === "Month") {
      style = "red";
    }
    return style;
  };

  return (
    <div className="service">
      <h3>Address Check / CRM - RESULTS</h3>
      <div className="serviceLeft">
        <p key="lastContact">
          <i className="serviceName">Last contact</i>
          <i className="colon">:</i>
          <i
            className={`serviceValue ${lastContactStyle(
              serviceValue.service.lastContact
            )}`}
          >
            {serviceValue.service.lastContact}
          </i>
        </p>
        <p key="FIS">
          <i className="serviceName">FIS(Fixed in SBL)</i>
          <i className="colon">:</i>
          <i className="serviceValue">{serviceValue.service.FIS}</i>
        </p>
        <p key="VoIP">
          <i className="serviceName">VoIP</i>
          <i className="colon">:</i>
          <i className="serviceValue">{serviceValue.service.VoIP}</i>
        </p>
        <p key="multiVoIP">
          <i className="serviceName">Multi VoIP</i>
          <i className="colon">:</i>
          <i className="serviceValue">{serviceValue.service.multiVoIP}</i>
        </p>
        <p key="accType">
          <i className="serviceName">Acc type</i>
          <i className="colon">:</i>
          <i className="serviceValue">{serviceValue.service.accType}</i>
        </p>
        <p key="SAM">
          <i className="serviceName">SAM</i>
          <i className="colon">:</i>
          <i className="serviceValue">{serviceValue.service.samId}</i>
        </p>
        <p key="SLAM">
          <i className="serviceName">SLAM</i>
          <i className="colon">:</i>
          <i className="serviceValue">{serviceValue.service.SLAM}</i>
        </p>
        <p key="ONT">
          <i className="serviceName">ONT Serial Number</i>
          <i className="colon">:</i>
          <i className="serviceValue">{serviceValue.ONT}</i>
        </p>
        <p key="ASID">
          <i className="serviceName">ASID</i>
          <i className="colon">:</i>
          <i className="serviceValue">{serviceValue.asid}</i>
        </p>
      </div>
      <div className="serviceRight">
        <p key="serviceName">
          <i className="serviceName">Service(Focus)</i>
          <i className="colon">:</i>
          {serviceValue.cartridge}
        </p>
        <p key="connectType">
          <i className="serviceName">Connect type</i>
          <i className="colon">:</i>
          {serviceValue.service.connectType}
        </p>
        <p key="planName">
          <i className="serviceName">Plan name</i>
          <i className="colon">:</i>
          {serviceValue.service.planName}
        </p>
        <p key="planSpeedDownload">
          <i className="serviceName">Plan speed</i>
          <i className="colon">:</i>
          {serviceValue.service.planSpeed.download &&
            `Download ${serviceValue.service.planSpeed.download}`}
        </p>
        <p key="planSpeedUpload">
          <i className="serviceName" />
          <i className="colon" />
          {serviceValue.service.planSpeed.download &&
            `Upload ${serviceValue.service.planSpeed.upload}`}
          {serviceValue.service.planSpeed.download && (
            <a
              className="learnMore"
              href="https://one.nz/broadband/understand-your-speed/"
              target="_blank"
              rel="noreferrer"
            >
              Learn More
            </a>
          )}
        </p>
        <p key="hardware">
          <i className="serviceName">Hardware</i>
          <i className="colon">:</i>
          {serviceValue.service.hardware.hardware}
        </p>
        <p key="serialNumber">
          <i className="serviceName">Serial number</i>
          <i className="colon">:</i>
          {serviceValue.service.hardware.serialNumber}
        </p>
        <p key="staticIP">
          <i className="serviceName">Static IP</i>
          <i className="colon">:</i>
          {serviceValue.staticIP}
        </p>
        <p key="IPv4">
          <i className="serviceName">IPv4</i>
          <i className="colon">:</i>
          {serviceValue.modemAuth.IPAddress}
        </p>
        <p key="username">
          <i className="serviceName">Username</i>
          <i className="colon">:</i>
          {serviceValue.username}
        </p>
      </div>
    </div>
  );
};

export default ServiceInfo;
