import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type EnvType = {
  env: string;
  poolId: string;
  poolDomain: string;
  clientId: string;
  region: string;
  oneAssistApi: string;
  adminApi: string;
  useTPIIProxy: boolean;
  outageApi: string;
};

type TokenType = {
  idToken: string;
  refreshToken: string;
};

type StateType = {
  login: EnvType;
  token: TokenType;
  isAuthenticated: boolean;
  username: string;
  userRole: string;
};

const initialState: StateType = {
  login: {
    env: "local",
    poolId: "",
    poolDomain: "ccaas-auth-dev",
    clientId: "",
    region: "ap-southeast-2",
    oneAssistApi: "",
    adminApi: "",
    useTPIIProxy: false,
    outageApi: "",
  },
  token: {
    idToken: "",
    refreshToken: "",
  },
  isAuthenticated: false,
  username: "",
  userRole: "",
};

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    updateEnv: (state, action: PayloadAction<EnvType>) => {
      state.login = action.payload;
    },
    updateAuthStatus: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
    updateToken: (state, action: PayloadAction<TokenType>) => {
      state.token = action.payload;
    },
    updateUserRole: (state, action: PayloadAction<string>) => {
      state.userRole = action.payload;
    },
    updateUsername: (state, action: PayloadAction<string>) => {
      state.username = action.payload;
    },
  },
});

export const {
  updateEnv,
  updateAuthStatus,
  updateToken,
  updateUserRole,
  updateUsername,
} = loginSlice.actions;

export default loginSlice.reducer;
