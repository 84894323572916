import React from "react";
import { NewInternetUsageType } from "../../helpers/ToolsetHelper";
import "./right.css";

type UsageTableProps = {
  data: NewInternetUsageType[];
};

const UsageTable: React.FC<UsageTableProps> = ({ data }: UsageTableProps) => {
  const tables: JSX.Element[] = [];
  const numTables = Math.ceil(data.length / 10);

  for (let i = 0; i < numTables; i += 1) {
    const startIndex = i * 10;
    const endIndex = Math.min(startIndex + 10, data.length);
    const tableData = data.slice(startIndex, endIndex);

    const table = (
      <table key={i} className="usageTable">
        <thead>
          <tr>
            <td className="rowName">Day</td>
            {tableData.map((row) => (
              <td className="days" key={row.DAY}>
                {row.DAY}
              </td>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="rowName">Download / Inbound</td>
            {tableData.map((row) => (
              <td className="inbound" key={row.DAY}>
                {row.INBOUND || "-"}
              </td>
            ))}
          </tr>
          <tr>
            <td className="rowName">Upload / Outbound</td>
            {tableData.map((row) => (
              <td className="outbound" key={row.DAY}>
                {row.OUTBOUND || "-"}
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    );

    tables.push(table);
  }

  return <>{tables}</>;
};

export default UsageTable;
