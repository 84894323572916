import React from "react";
import { useAppSelector } from "../../redux/hooks";

const CustomerInfo: React.FC = () => {
  const customerInfoState = useAppSelector((state) => state.customer);

  const showPill = (): string => {
    if (/active/i.test(customerInfoState.customerInfo.Status)) {
      return "No";
    }
    return "Yes";
  };

  return (
    <div className="customerInfo">
      <div className="column1">
        <p className="infoWrapper">
          <i className="infoTitle">Name</i>
          <i className="colon">:</i>
          <i className="infoValue">{customerInfoState.customerInfo.Name}</i>
        </p>
        <p className="infoWrapper">
          <i className="infoTitle">Account</i>
          <i className="colon">:</i>
          <i className="infoValue">
            {customerInfoState.customerInfo.AccountID}
          </i>
        </p>
        <p className="infoWrapper">
          <i className="infoTitle">CRM</i>
          <i className="colon">:</i>
          <i className="infoValue">{customerInfoState.customerInfo.CRM}</i>
        </p>
        <p className="infoWrapper">
          <i className="infoTitle">Status</i>
          <i className="colon">:</i>
          <i
            className={`${
              customerInfoState.customerInfo.Status && showPill() === "Yes"
                ? "redLight"
                : ""
            }`}
          />
          <i className="infoValue">{customerInfoState.customerInfo.Status}</i>
        </p>
        <p className="infoWrapper">
          <i className="infoTitle">PIN</i>
          <i className="colon">:</i>
          <i className="infoValue">{customerInfoState.customerInfo.PIN}</i>
        </p>
      </div>
      <div className="column2">
        <p className="infoWrapper">
          <i className="infoTitle">Acct Name</i>
          <i className="colon">:</i>
          <i className="infoValue">{customerInfoState.customerInfo.AcctName}</i>
        </p>
        <p className="infoWrapper">
          <i className="infoTitle">DOB</i>
          <i className="colon">:</i>
          <i className="infoValue">{customerInfoState.customerInfo.DOB}</i>
        </p>
        <p className="infoWrapper">
          <i className="infoTitle">Email</i>
          <i className="colon">:</i>
          <i className="infoValue">{customerInfoState.customerInfo.Email}</i>
        </p>
        <p className="infoWrapper">
          <i className="infoTitle">Mobile</i>
          <i className="colon">:</i>
          <i className="infoValue">{customerInfoState.customerInfo.Mobile}</i>
        </p>
      </div>
      <div className="column3">
        <p className="infoWrapper">
          <i className="infoTitle">CRM Service Address</i>
          <i className="colon">:</i>
          <i className="infoValue">
            {customerInfoState.customerInfo.CRMServiceAddress}
          </i>
        </p>
      </div>
    </div>
  );
};

export default CustomerInfo;
