import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";
import { useAppSelector } from "../../redux/hooks";
import {
  combineInternetUsageData,
  converToMonthName,
  sumUsageData,
} from "../../helpers/ToolsetHelper";
import UsageTable from "./UsageTable";
import "./right.css";

const InternetUsage: React.FC = () => {
  const { internetUsage } = useAppSelector((state) => state.service);
  const combinedData = combineInternetUsageData(internetUsage);

  return (
    <div className="internetUsage">
      <h3>Internet Usage - RESULTS</h3>
      {combinedData.length > 0 && (
        <div>
          <div className="titleArea">
            <div className="month">
              <b>{converToMonthName(combinedData[0].MONTH)}</b>{" "}
              {combinedData[0].TRAFFIC_TYPE}
            </div>
            <div className="data">
              <p>
                Download / Inbound:
                <b>{sumUsageData(combinedData, "INBOUND")}</b> MB
              </p>
              <p>
                Upload / Outbound:
                <b>{sumUsageData(combinedData, "OUTBOUND")}</b> MB
              </p>
            </div>
          </div>
          <AreaChart
            width={700}
            height={400}
            data={combinedData}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="DAY" />
            <YAxis />
            <Tooltip />
            <Area
              type="monotone"
              dataKey="INBOUND"
              stroke="#a9d18e"
              fill="#a9d18e"
            />
            <Area
              type="monotone"
              dataKey="OUTBOUND"
              stroke="#e2f0d9"
              fill="#e2f0d9"
            />
          </AreaChart>
          <UsageTable data={combinedData} />
        </div>
      )}
    </div>
  );
};

export default InternetUsage;
