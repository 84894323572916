import React, { useState, useCallback, useEffect } from "react";
import axios from "axios";
import DOMPurify from "dompurify";
import { Scrollbars } from "react-custom-scrollbars-2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { updateCopyNotice } from "../../redux/globalSlice";
import AccountInfo from "./AccountInfo";
import ServiceInfo from "./ServiceInfo";
import Outages from "./Outages";
import ModemAuth from "./ModemAuth";
import LanInfo from "./LanInfo";
import LoginHistory from "./LoginHistory";
import LineTestONT from "./LineTestONT";
import InternetUsage from "./InternetUsage";
import ONTOvertimeChart from "./ONTOvertime";
import "./right.css";

const RightWrapper: React.FC = () => {
  const copyNotice = useAppSelector((state) => state.global.copyNotice);
  const { idToken } = useAppSelector((state) => state.login.token);
  const { adminApi } = useAppSelector((state) => state.login.login);
  const [urgentMessage, setUrgentMessage] = useState("");
  const dispatch = useAppDispatch();

  const copyToClipboard = (): void => {
    const coptText = (document.getElementById("rightWrapper") as HTMLElement)
      .innerText;

    navigator.permissions
      .query({ name: "clipboard-write" as PermissionName })
      .then((result) => {
        if (result.state === "granted" || result.state === "prompt") {
          navigator.clipboard.writeText(coptText).then(
            () => {
              dispatch(updateCopyNotice("Copied!"));
            },
            () => {
              dispatch(updateCopyNotice("Copy failed!"));
            }
          );
        }
      });
  };

  const getUrgentMessage = useCallback(() => {
    let message = "";
    axios
      .get(`${adminApi}/platform/messages-public/OneAssist/key/bannerMessage`, {
        headers: { Authorization: idToken },
      })
      .then((response) => {
        message = response.data.message_text;
        setUrgentMessage(message);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [idToken, adminApi]);

  useEffect(() => {
    getUrgentMessage();
    setInterval(getUrgentMessage, 15 * 60 * 1000);
  }, [getUrgentMessage]);

  return (
    <div className="rightWrapper" id="rightWrapper">
      <Scrollbars className="scrollbar">
        <div className="copyWrapper">
          <button
            type="button"
            className="copyBtn"
            onClick={() => {
              copyToClipboard();
            }}
          >
            <FontAwesomeIcon icon={faCopy} pull="left" className="copy" />
          </button>
          <p className="copyNotice">{copyNotice}</p>
        </div>
        {urgentMessage ? (
          <p className="urgentMsg">
            <span // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(urgentMessage),
              }}
            />
          </p>
        ) : (
          <div />
        )}
        <AccountInfo />
        <ServiceInfo />
        <Outages />
        <ModemAuth />
        <LanInfo />
        <LoginHistory />
        <LineTestONT />
        <InternetUsage />
        <ONTOvertimeChart />
      </Scrollbars>
    </div>
  );
};

export default RightWrapper;
