import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type OutageType = {
  outageLight: string;
  outageTitle: string;
  outageNotice: string;
};

type LoginHistoryType = {
  isGetLoginHistory: boolean;
  loginNotice: string;
};

type ONTStatusType = {
  ONTStatusLight: string;
  ONTStatusNotice: string;
};

type RGWBandWidthType = {
  rgwBandWidthLight: string;
  rgwBandWidthNotice: string;
  rgwBandWidthDownstream: string;
  rgwBandWidthUpstream: string;
};

type InternetUsageType = {
  isGetInternetUsage: boolean;
  notice: string;
};

type ONTOverTimeType = {
  isGetONTOverTime: boolean;
  notice: string;
};

type StateType = {
  unplannedOutage: OutageType;
  plannedOutage: OutageType;
  inEffectOutage: OutageType;
  futureOutage: OutageType;
  modemAuthNotice: string;
  isLanTested: boolean;
  lanTestNotice: string;
  loginHistory: LoginHistoryType;
  ONTStatus: ONTStatusType;
  rgwBandWidth: RGWBandWidthType;
  internetUsage: InternetUsageType;
  ONTOverTime: ONTOverTimeType;
};

const initialState: StateType = {
  unplannedOutage: {
    outageLight: "",
    outageTitle: "",
    outageNotice: "",
  },
  plannedOutage: {
    outageLight: "",
    outageTitle: "",
    outageNotice: "",
  },
  inEffectOutage: {
    outageLight: "",
    outageTitle: "",
    outageNotice: "",
  },
  futureOutage: {
    outageLight: "",
    outageTitle: "",
    outageNotice: "",
  },
  modemAuthNotice: "",
  isLanTested: false,
  lanTestNotice: "",
  loginHistory: {
    isGetLoginHistory: false,
    loginNotice: "",
  },
  ONTStatus: {
    ONTStatusLight: "",
    ONTStatusNotice: "",
  },
  rgwBandWidth: {
    rgwBandWidthLight: "",
    rgwBandWidthNotice: "",
    rgwBandWidthDownstream: "",
    rgwBandWidthUpstream: "",
  },
  internetUsage: {
    isGetInternetUsage: false,
    notice: "",
  },
  ONTOverTime: {
    isGetONTOverTime: false,
    notice: "",
  },
};

export const toolsetSlice = createSlice({
  name: "toolset",
  initialState,
  reducers: {
    clearOutageStatus: (state) => {
      state.unplannedOutage = {
        outageLight: "",
        outageTitle: "",
        outageNotice: "",
      };
      state.plannedOutage = {
        outageLight: "",
        outageTitle: "",
        outageNotice: "",
      };
      state.inEffectOutage = {
        outageLight: "",
        outageTitle: "",
        outageNotice: "",
      };
      state.futureOutage = {
        outageLight: "",
        outageTitle: "",
        outageNotice: "",
      };
    },
    updateUnplannedOutage: (state, action: PayloadAction<OutageType>) => {
      state.unplannedOutage = action.payload;
    },
    updatePlannedOutage: (state, action: PayloadAction<OutageType>) => {
      state.plannedOutage = action.payload;
    },
    updateInEffectOutage: (state, action: PayloadAction<OutageType>) => {
      state.inEffectOutage = action.payload;
    },
    updateFutureOutage: (state, action: PayloadAction<OutageType>) => {
      state.futureOutage = action.payload;
    },
    updateModemAuthNotice: (state, action: PayloadAction<string>) => {
      state.modemAuthNotice = action.payload;
    },
    updateLanTestStatus: (state, action: PayloadAction<boolean>) => {
      state.isLanTested = action.payload;
    },
    updateLanTestNotice: (state, action: PayloadAction<string>) => {
      state.lanTestNotice = action.payload;
    },
    updateLoginHistoryStatus: (
      state,
      action: PayloadAction<LoginHistoryType>
    ) => {
      state.loginHistory = action.payload;
    },
    updateONTStatus: (state, action: PayloadAction<ONTStatusType>) => {
      state.ONTStatus = action.payload;
    },
    updatergwBandWidth: (state, action: PayloadAction<RGWBandWidthType>) => {
      state.rgwBandWidth = action.payload;
    },
    updateInternetUsageStatus: (
      state,
      action: PayloadAction<InternetUsageType>
    ) => {
      state.internetUsage = action.payload;
    },
    updateONTOverTimeStatus: (
      state,
      action: PayloadAction<ONTOverTimeType>
    ) => {
      state.ONTOverTime = action.payload;
    },
    clearToolsetData: () => {
      return initialState;
    },
  },
});

export const {
  clearOutageStatus,
  updateUnplannedOutage,
  updatePlannedOutage,
  updateInEffectOutage,
  updateFutureOutage,
  updateModemAuthNotice,
  updateLanTestStatus,
  updateLanTestNotice,
  updateLoginHistoryStatus,
  updateONTStatus,
  updatergwBandWidth,
  updateInternetUsageStatus,
  updateONTOverTimeStatus,
  clearToolsetData,
} = toolsetSlice.actions;

export default toolsetSlice.reducer;
