import moment from "moment-timezone";

export const convertToNZTime = (utcTime: string): moment.Moment => {
  const nzTime = moment.utc(utcTime).tz("Pacific/Auckland");
  return nzTime;
};

export const formattedDate = (originalDate: string): string => {
  const nzTime = moment(originalDate).tz("Pacific/Auckland");
  return nzTime.format("h:mma DD/MM/YYYY");
};

export const formatOutageDuration = (duration: number): string => {
  if (duration < 60) {
    return `${duration} mins`;
  }
  if (duration < 1440) {
    const hours = Math.floor(duration / 60);
    const minutes = duration % 60;

    if (hours === 1) {
      if (minutes === 0) {
        return "1 hour";
      }
      return `1 hour ${minutes} mins`;
    }

    if (minutes === 0) {
      return `${hours} hours`;
    }

    return `${hours} hours ${minutes} mins`;
  }

  const days = Math.floor(duration / 1440);
  const hours = Math.floor((duration % 1440) / 60);
  const minutes = duration % 60;

  if (days === 1) {
    if (hours === 0) {
      if (minutes === 0) {
        return "1 day";
      }
      return `1 day ${minutes} mins`;
    }

    if (hours === 1) {
      if (minutes === 0) {
        return "1 day 1 hour";
      }
      return `1 day 1 hour ${minutes} mins`;
    }

    if (minutes === 0) {
      return `1 day ${hours} hours`;
    }

    return `1 day ${hours} hours ${minutes} mins`;
  }

  if (hours === 1) {
    if (minutes === 0) {
      return `${days} days 1 hour`;
    }

    return `${days} days 1 hour ${minutes} mins`;
  }

  if (minutes === 0) {
    if (hours === 0) {
      return `${days} days`;
    }
    return `${days} days ${hours} hours`;
  }

  return `${days} days ${hours} hours ${minutes} mins`;
};
