/* eslint-disable class-methods-use-this */
import React, { createRef } from "react";
import { logger } from "../config/Config";
import { IClientConfig } from "../config/ClientConfig";
import "amazon-connect-streams";
import "../style/App.css";
import { store } from "../redux/store";
import { getCIRNumber } from "../redux/globalSlice";

type ConfigFunction = () => IClientConfig;

declare global {
  interface Window {
    get_client_config: ConfigFunction;
  }
}
interface ProcessEnv { // eslint-disable-line
  [key: string]: string | undefined;
}
export default class OneView extends React.Component<unknown, unknown> {
  accountNumer = createRef<HTMLDivElement>();

  ccp = createRef<HTMLDivElement>();

  ccpURL = "";

  loginURL = "";

  logoutURL = "";

  instanceHome = "";

  agentMetricsURL = "";

  instanceAlias = "";

  payload: string;

  // newMessageAudio = new Audio("../public/sounds/new-message.mp3");

  constructor(props: unknown) {
    super(props);
    this.payload = "DO NOT CLOSE";
  }

  componentDidMount(): void {
    const accountNumberNode = this.accountNumer.current;
    document.title = this.payload;
    if (accountNumberNode) {
      accountNumberNode.innerHTML = this.payload;
    }
    const script = document.createElement("script");
    let confURL = process.env["REACT_APP_CONFIG_URL"]; // eslint-disable-line @typescript-eslint/dot-notation
    if (!confURL) {
      confURL = "Config.js";
    }
    script.src = confURL;
    script.async = true;
    script.onload = () => this.scriptLoaded();
    document.body.appendChild(script);
  }

  scriptLoaded(): void {
    logger.info("scriptLoaded");
    const config = window.get_client_config(); // eslint-disable-line @typescript-eslint/no-explicit-any
    this.instanceAlias = config.instanceAlias;

    if (config.ssoURL) {
      this.loginURL = config.ssoURL;
    } else {
      this.loginURL = `https://${this.instanceAlias}.awsapps.com/connect/login`;
    }

    this.logoutURL = `https://${this.instanceAlias}.awsapps.com/connect/logout`;
    this.instanceHome = `https://${this.instanceAlias}.awsapps.com/connect/home`;
    this.ccpURL = `https://${this.instanceAlias}.awsapps.com/connect/ccp-v2`;
    this.agentMetricsURL = `https://${this.instanceAlias}.awsapps.com/connect/real-time-metrics?tableType=user`;

    const ccpNode = this.ccp.current;
    if (ccpNode) {
      connect.core.initCCP(ccpNode, {
        ccpUrl: this.ccpURL,
        loginUrl: this.loginURL,
        loginPopup: true,
        loginPopupAutoClose: true,
        region: "ap-southeast-2",
        softphone: {
          allowFramedSoftphone: false,
        },
      });
      this.initConnectInstance();
    }
  }

  encodeContactAttributes(
    callingInRegards: string,
    queue: connect.Queue,
    calledFromNumber: string | undefined,
    calledToNumber: string | undefined
  ): string {
    let returner = `calledFromNumber|${calledFromNumber}`;
    returner += ` calledToNumber|${calledToNumber}`;
    returner += ` queueName|${queue.name}`;
    returner += ` callingInRegards|${callingInRegards}`;
    return returner;
  }

  contactConnected(contactEvent: connect.Contact): void {
    const attributeMap = contactEvent.getAttributes();
    const queue = contactEvent.getQueue();
    const calledFromNumber = contactEvent.getActiveInitialConnection()?.getEndpoint().phoneNumber; // eslint-disable-line
    const calledToNumber = contactEvent.getAgentConnection()?.getEndpoint().phoneNumber; // eslint-disable-line
    let callingInRegards = "";
    if (attributeMap.agt_calling_in_regards) {
      callingInRegards = attributeMap.agt_calling_in_regards.value;
      store.dispatch(getCIRNumber(callingInRegards));
    }
    document.title = this.encodeContactAttributes(
      callingInRegards,
      queue,
      calledFromNumber,
      calledToNumber
    );
    const accountNumberNode = this.accountNumer.current;
    if (accountNumberNode) {
      accountNumberNode.innerHTML = `<p>calledFromNumber|${calledFromNumber}</p>
      <p>calledToNumber|${calledToNumber}</p>
      <p>queueName|${queue.name}</p>
      <p>callingInRegards|${callingInRegards}</p>`;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  contactEnded(contactEvent: connect.Contact): void {
    const accountNumberNode = this.accountNumer.current;
    if (accountNumberNode) {
      document.title = this.payload;
      accountNumberNode.innerHTML = this.payload;
    }
  }

  subscribeContact(contact: connect.Contact): void {
    contact.onConnected((contactConnectedEvent: connect.Contact) =>
      this.contactConnected(contactConnectedEvent)
    );
    contact.onEnded((contactConnectedEvent: connect.Contact) =>
      this.contactEnded(contactConnectedEvent)
    );
  }

  initConnectInstance(): void {
    connect.contact((contact: connect.Contact) =>
      this.subscribeContact(contact)
    );
  }

  render(): JSX.Element | null {
    return (
      <div id="oneview">
        <div id="ccp" ref={this.ccp} />
        <div id="accountNumber" ref={this.accountNumer}>
          DO NOT CLOSE
        </div>
      </div>
    );
  }
}
