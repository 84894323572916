import React, { useEffect, useState } from "react";
import axios from "axios";
import jwt from "jwt-decode";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { store, RootState } from "../../redux/store";
import {
  updateAuthStatus,
  updateToken,
  updateUserRole,
  updateUsername,
} from "../../redux/loginSlice";
import SignInButton from "./SignInButton";
import InputWrapper from "../left/InputWrapper/InputWrapper";
import PinVerify from "../left/PIN/PinVerify";
import RightWrapper from "../right/RightInfoWrapper";
import TopWrapper from "../top/TopInfoWrapper";
import OneView from "../OneView";
import Loader from "./Loader";
import "./login.css";
import ToolsetWrapper from "../left/Toolset/ToolsetWrapper";

const decodeToken = (codedToken: string): string => {
  const userInfo: any = jwt(codedToken); // eslint-disable-line
  const roleProfile = ["NOT FOUND"];
  let userRole = "";
  const userRoleGroup: string[] = userInfo["cognito:groups"];
  userRoleGroup.forEach((element) => {
    if (roleProfile.indexOf(element) >= 0) {
      userRole = element;
    }
  });
  return userRole;
};

export const getNewToken = (): void => {
  const { clientId, poolDomain, region } = store.getState().login.login;
  const { refreshToken } = store.getState().login.token;
  const details = {
    grant_type: "refresh_token",
    client_id: clientId,
    refresh_token: refreshToken,
  };
  const formBody = Object.entries(details)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join("&");

  axios
    .post(
      `https://${poolDomain}.auth.${region}.amazoncognito.com/oauth2/token`,
      formBody,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    )
    .then((res) => {
      store.dispatch(
        updateToken({
          idToken: res.data.id_token,
          refreshToken: "",
        })
      );
      store.dispatch(updateUserRole(decodeToken(res.data.id_token)));
    })
    .catch((error) => {
      console.error(error);
    });
};

const LoginLayout: React.FC = () => {
  const dispatch = useAppDispatch();
  const userAuth = useAppSelector(
    (state: RootState) => state.login.isAuthenticated
  );
  const { region, clientId, poolDomain } = useAppSelector(
    (state: RootState) => state.login.login
  );
  const port = window.location.port ? `:${window.location.port}` : "";
  const redirectUri = `${window.location.protocol}//${window.location.hostname}${port}`;
  const [loader, setLoader] = useState(false);
  const [token, setToken] = useState(false);
  const [gettingToken, setGettingToken] = useState(false);

  const [isRefreshTimerStart, setIsRefreshTimerStart] = useState(false);
  const [isLogoutTimerStart, setIsLogoutTimerStart] = useState(false);
  let refreshTimer = 0;
  let logoutTimer = 0;

  if (isRefreshTimerStart && !refreshTimer) {
    setIsRefreshTimerStart(false);
    refreshTimer = window.setTimeout(() => {
      getNewToken();
      clearTimeout(refreshTimer);
      setIsLogoutTimerStart(true);
    }, 1000 * 60 * 58);
  }

  if (isLogoutTimerStart && !logoutTimer) {
    setIsLogoutTimerStart(false);
    logoutTimer = window.setTimeout(() => {
      clearTimeout(logoutTimer);
      window.location.href = redirectUri;
    }, 1000 * 60 * 58);
  }

  const getUserInfo = (passtoken: string): void => {
    axios
      .get(
        `https://${poolDomain}.auth.${region}.amazoncognito.com/oauth2/userInfo`,
        {
          headers: {
            Authorization: `Bearer ${passtoken}`,
          },
        }
      )
      .then((res) => {
        dispatch(updateUsername(res.data.name));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getTokenbyCode = (code: string) => {
    setGettingToken(true);
    setLoader(true);
    const details = {
      grant_type: "authorization_code",
      code,
      client_id: clientId,
      redirect_uri: redirectUri,
    };
    const formBody = Object.entries(details)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");

    axios
      .post(
        `https://${poolDomain}.auth.${region}.amazoncognito.com/oauth2/token`,
        formBody,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        dispatch(
          updateToken({
            idToken: res.data.id_token,
            refreshToken: res.data.refresh_token,
          })
        );
        dispatch(updateUserRole(decodeToken(res.data.id_token)));
        getUserInfo(res.data.access_token);
        setLoader(false);
        setToken(true);
        setGettingToken(false);
        dispatch(updateAuthStatus(true));
        setIsRefreshTimerStart(true);
      })
      .catch((error) => {
        if (userAuth) {
          dispatch(updateAuthStatus(false));
        }
        setLoader(false);
        window.location.href = redirectUri;
        console.error(error);
      });
  };

  useEffect(() => {
    if (
      clientId &&
      !gettingToken &&
      !userAuth &&
      !token &&
      window.location.search.match(/code=([^&]+)/)
    ) {
      const code = window.location.search.match(/code=([^&]+)/);
      if (!code) return;
      if (
        /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i.test(
          code[1]
        )
      ) {
        // send login auth request
        getTokenbyCode(code[1]);
      }
    }
  });

  return (
    <div className={userAuth ? "authed" : "noauthed"}>
      {userAuth ? (
        <div className="content">
          <div className="App">
            <OneView />
            <TopWrapper />
            <div className="left">
              <div className="searchWrapper">
                <InputWrapper />
                <PinVerify />
              </div>
              <ToolsetWrapper />
            </div>
            <div className="right">
              <RightWrapper />
            </div>
          </div>
        </div>
      ) : (
        [
          loader ? (
            <div key="loader">
              <Loader />
            </div>
          ) : (
            <div className="noauth-header" key="noauth-header">
              <div className="header-center">
                <h4 className="header-title">Welcome to One Assist</h4>
                <div className="header-img" />
              </div>
              <div className="noauth-notice">
                <SignInButton />
              </div>
            </div>
          ),
        ]
      )}
    </div>
  );
};

export default LoginLayout;
