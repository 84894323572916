import React from "react";
import { useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";

const SignInButton: React.FC = () => {
  const { poolDomain, region, clientId } = useAppSelector(
    (state: RootState) => state.login.login
  );
  const port = window.location.port ? `:${window.location.port}` : "";
  const redirectUri = `${window.location.protocol}//${window.location.hostname}${port}`;
  const loginURL = `https://${poolDomain}.auth.${region}.amazoncognito.com/login?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}`;

  const handleLogin = (url: string): void => {
    window.location.href = url;
  };

  return (
    <button
      type="button"
      className="signInButton"
      id="signInBtn"
      onClick={() => handleLogin(loginURL)}
    >
      Sign in
    </button>
  );
};

export default SignInButton;
