import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type WIFIType = {
  dropdown?: string;
  dropdown_title?: string;
  headline: string;
  message_body: string;
  message_header: string;
  shown_dropdown?: string;
};

type StateType = {
  wifiData: WIFIType;
  wifiKey: string;
};

const initialState: StateType = {
  wifiData: {
    dropdown: "",
    dropdown_title: "",
    headline: "",
    message_body: "",
    message_header: "",
    shown_dropdown: "",
  },
  wifiKey: "",
};

export const wifiSlice = createSlice({
  name: "wifi",
  initialState,
  reducers: {
    // eslint-disable-next-line
    updateWifiData: (state, action: PayloadAction<any>) => {
      state.wifiData = { ...action.payload };
    },
    updateWifiKey: (state, action: PayloadAction<string>) => {
      state.wifiKey = action.payload;
    },
    clearWifiData: () => {
      return initialState;
    },
  },
});

export const {
  updateWifiData,
  updateWifiKey,
  clearWifiData,
} = wifiSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value;

export default wifiSlice.reducer;
