import axios from "axios";
import { store } from "../redux/store";
import { showProgressBar } from "../redux/globalSlice";
import {
  updateONT,
  updateLineTestONT,
  getLineTestLan,
} from "../redux/serviceSlice";
import {
  updateLanTestStatus,
  updateLanTestNotice,
  updateONTStatus,
  updatergwBandWidth,
  updateONTOverTimeStatus,
} from "../redux/toolsetSlice";
import { loopOfGetOntStatus, getONTStatus } from "./LineTestHelper";

interface TestIDOutput {
  [key: string]: string;
}

interface TestTask {
  testId: string;
  testType: string;
  status: string;
  creationTime: string;
  lastUpdateTime: string;
  endTime: string | null;
  message: string;
}

// eslint-disable-next-line
const extractTestIds = (data: any): TestIDOutput => {
  const output: TestIDOutput = {};
  output.lfc = data.lfc;
  data.submittedTestTasks.forEach((task: TestTask) => {
    output[task.testType] = task.testId;
  });
  return output;
};

export const getLineTestTypesAndTestID = async (
  asid: string
): Promise<void> => {
  store.dispatch(
    showProgressBar({
      isShown: true,
      pbMessage: "Getting line test types...",
    })
  );
  const { login, token } = store.getState().login;
  try {
    const response = await axios.post(
      `${login.outageApi}/linetest/task/submit`,
      { asid },
      {
        headers: {
          Authorization: token.idToken,
        },
      }
    );
    const testTypes = extractTestIds(response.data);
    if (
      testTypes.lfc === "enable" ||
      testTypes.lfc === "tff" ||
      testTypes.lfc === "northpower"
    ) {
      store.dispatch(
        showProgressBar({
          isShown: true,
          pbMessage: "Getting ONT status",
        })
      );
      // Get ONT Status
      await loopOfGetOntStatus(testTypes.ontStatus, "auto");
      store.dispatch(
        updateONTOverTimeStatus({
          isGetONTOverTime: false,
          notice: "Data not available for this provider",
        })
      );
    }
    store.dispatch(
      showProgressBar({
        isShown: false,
        pbMessage: "",
      })
    );
  } catch (error) {
    console.error(error);
  }
};

export const otherLFCLineTest = async (
  isAutoRun: boolean,
  asid: string,
  action?: string
): Promise<void> => {
  if (isAutoRun) {
    // clear first
    store.dispatch(
      getLineTestLan({
        CV: "",
        SV: "",
        handover: "",
      })
    );
    store.dispatch(updateLanTestStatus(false));
    store.dispatch(updateLanTestNotice(""));
    store.dispatch(updateONT(""));
    store.dispatch(
      updateONTStatus({
        ONTStatusLight: "",
        ONTStatusNotice: "",
      })
    );
    store.dispatch(
      updatergwBandWidth({
        rgwBandWidthLight: "",
        rgwBandWidthNotice: "",
        rgwBandWidthDownstream: "",
        rgwBandWidthUpstream: "",
      })
    );
    store.dispatch(
      updateLineTestONT({
        id: "",
        address: [""],
        serviceType: "",
        plan: "",
        port: "",
        type: "",
        taggingMode: "",
        circuitInsertion: "",
      })
    );
  }

  if (asid) {
    if (isAutoRun) {
      await getLineTestTypesAndTestID(asid);
    } else if (action === "lan") {
      // clear first
      store.dispatch(
        getLineTestLan({
          CV: "",
          SV: "",
          handover: "",
        })
      );
      store.dispatch(updateLanTestNotice(""));
      store.dispatch(updateLanTestStatus(false));
      getONTStatus(asid, "lan");
    } else if (action === "ONT") {
      // clear first
      store.dispatch(updateONT(""));
      store.dispatch(
        updateONTStatus({
          ONTStatusLight: "",
          ONTStatusNotice: "",
        })
      );
      store.dispatch(
        updatergwBandWidth({
          rgwBandWidthLight: "",
          rgwBandWidthNotice: "",
          rgwBandWidthDownstream: "",
          rgwBandWidthUpstream: "",
        })
      );
      store.dispatch(
        updateLineTestONT({
          id: "",
          address: [""],
          serviceType: "",
          plan: "",
          port: "",
          type: "",
          taggingMode: "",
          circuitInsertion: "",
        })
      );
      getONTStatus(asid, "ONT");
    }
  } else {
    store.dispatch(updateLanTestStatus(false));
    store.dispatch(updateLanTestNotice("No ASID found"));
    store.dispatch(
      updateONTStatus({
        ONTStatusLight: "redLight",
        ONTStatusNotice: "No ASID found",
      })
    );
  }
};
